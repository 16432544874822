import useOrders from "../../../../newStructure/services/state/account/useOrders";
import useValueChangeListener from "../../../../newStructure/services/state/general/useValueChangeListener";
import {useNavigate} from "react-router-dom";
import SkeletonDiv from "../../../general/loading/SkeletonDiv";
import {api} from "../../../../res/rest/api";
import {env} from "../../../../res/config/env";
import usePrint from "../../../general/output/usePrint";
import {useDispatch} from "react-redux";
import {request} from "../../../../res/rest/restRequest";
import LineOrder from "../ordering/LineOrder";
import {reportError} from "../../../../redux/slice/errorReportSlice";

const reportAnError = (dispatch, e) => {
    dispatch(reportError({
        code: "shipmentPrintError",
        error: e && e.message ? e.message : "failed to fetch report"
    }))
}

const AccountLayoutDetailOrder = ({id}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading: loadingPDF, fetchAndPrintIt } = usePrint({
        url: request.print(api.MODEL.C_ORDER, id).buildRequest(env.API_URL),
        onError: (e) => reportAnError(dispatch, e)
    });

    const {
        loading,
        orders,
        getOrders
    } = useOrders();

    const getTheOrder = () => {
        getOrders({
            orderIDs: [id],
            includeOrderLines: true
        })
    };

    useValueChangeListener(getTheOrder, [id], []);

    const goToOrders = () => navigate("/account/ordering/orders");
    const order = orders && orders.length > 0 ? orders[0] : null;
    const lines = order?.c_orderline;

    return (
        <div className="account-order-detail-view">
            <div className="account-order-detail-header">
                <p className="clickable-text" onClick={goToOrders}>Back To Orders</p>
                {loading ?
                    <SkeletonDiv style={{height:"2.4em", width:"min(300pt, 40vw)", marginBottom: "10pt"}}/> :
                    <p className="page-h1">Order: {order ? order.DocumentNo : ""}</p>
                }
                <div className="account-order-detail-buttons">
                    {/*<Button>Add all to cart</Button>*/}
                    {/*<Button>Create recurring order</Button>*/}
                    {loadingPDF ?
                        <SkeletonDiv style={{height: "calc(1em + 10px)", width: "10em"}} /> :
                        <p className="clickable-text"
                           onClick={fetchAndPrintIt}
                           style={{margin: "4px 0", color: "var(--secondary)"}}>
                            Print Order
                        </p>
                    }
                </div>

                {/* TODO */}
                {/*<div className="account-order-detail-links">*/}
                {/*    <p style={{marginTop: "32px", marginBottom: "12px"}} className="page-h3">Linked Shipments</p>*/}
                {/*    <p style={{marginTop: "24px", marginBottom: "12px"}} className="page-h3">Linked Invoices</p>*/}

                {/*</div>*/}

                <div className="account-order-detail-lines">
                    <p style={{marginTop: "32px", marginBottom: "12px"}} className="page-h3">Order Lines</p>
                    <div className="account-order-lines-display">
                        {loading ?
                            [...Array(4).keys()].map((e,i) =>
                                <SkeletonDiv style={{height: "2em", width: "400px"}} key={i}/>) :
                                (!loading && lines) &&
                                lines.map((e,i) =>
                                    <LineOrder
                                        key={i}
                                        orderLine={e}
                                    />
                                )
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

// const AccountLayoutDetailOrder = ({id}) => {
//
//     const navigate = useNavigate();
//
//     const [ reloaded, setReloaded ] = useState(false);
//
//     const {
//         ordering,
//         loading,
//         error,
//         getAnOrder,
//         getAnOrdersLines
//     } = useOrders();
//
//
//     const order = ordering.orders ? ordering.orders[id] : null;
//     if(!reloaded) {
//         setReloaded(true);
//         getAnOrder(id);
//         getAnOrdersLines(id);
//     }
//
//     const dispatch = useDispatch();
//     const { loading: loadingPDF, fetchAndPrintIt } = usePrint({
//         url: request.print(api.MODEL.C_ORDER, id).buildRequest(env.API_URL),
//         onError: (e) => dispatch(reportError({
//             code: "shipmentPrintError",
//             error: e && e.message ? e.message : "failed to fetch report"
//         }))
//     })
//
//     const displayOrderLoading = !reloaded || (!order && loading.order);
//
//     const orderLines = ordering.orderLines;
//     const linkedInvoices  = (ordering.invoices && reloaded)  ? Object.values(ordering.invoices)  : [];
//     const linkedShipments = (ordering.shipments && reloaded) ? Object.values(ordering.shipments) : [];
//
//     const displayLinesLoading = !reloaded || loading.order
//         || loading.orderLines || error.orderLines || !orderLines;
//
//     const links = {};
//     if(orderLines && orderLines[id]) orderLines[id].forEach(ol =>
//         links[ol.id] = getOrderLineLinks(ol, ordering)
//     );
//
//     return (
//         <div className="account-order-detail-view">
//
//             <div className="account-order-detail-header">
//                 <p className="clickable-text" onClick={() => navigate("/account/ordering/orders")}>Back To Orders</p>
//                 {displayOrderLoading ?
//                     <SkeletonDiv style={{height:"2.4em", width:"min(300pt, 40vw)", marginBottom: "10pt"}}/> :
//                     <p className="account-header-2">Order: {order ? order.DocumentNo : ""}</p>
//                 }
//                 <div className="account-order-detail-buttons">
//                     {/*<Button>Add all to cart</Button>*/}
//                     {/*<Button>Create recurring order</Button>*/}
//                     <Button className={loadingPDF ? "button-loading" : null} onClick={fetchAndPrintIt}>Print Order</Button>
//                     <Button>Return Items</Button>
//                 </div>
//             </div>
//
//             <div className="account-order-detail-links">
//                 <Linked
//                     links={linkedInvoices}
//                     header={"Linked Invoices"}
//                     loading={loading.invoices}
//                     toComponent={(e,i) => <CompactInvoice key={i} invoice={e} />}
//                 />
//                 <Linked
//                     links={linkedShipments}
//                     header={"Linked Shipments"}
//                     loading={loading.shipments}
//                     toComponent={(e,i) => <CompactShipment key={i} shipment={e} />}
//                 />
//             </div>
//
//             <div className="account-order-detail-lines">
//                 <p className="account-header-2">Order Lines</p>
//                 <div className="account-order-lines-display">
//                     {displayLinesLoading ?
//                         [...Array(4).keys()].map((e,i) =>
//                             <HeaderSkeleton key={i}/>) :
//                         orderLines[id].map((e,i) =>
//                             <LineOrder
//                                 key={i}
//                                 orderLine={e}
//                                 invoices={links[e.id].invoices}
//                                 shipments={links[e.id].shipments}
//                             />)
//                     }
//                 </div>
//             </div>
//
//         </div>
//     )
// }

export default AccountLayoutDetailOrder;