
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import useInvoices from "../../../../newStructure/services/state/account/useInvoices";
import useValueChangeListener from "../../../../newStructure/services/state/general/useValueChangeListener";
import {useMemo, useState} from "react";
import Invoice from "../../../../newStructure/logic/account/invoice";
import RangeSelector from "../../../account/component/RangeSelector";
import SkeletonDiv from "../../../general/loading/SkeletonDiv";
import HeaderInvoice from "../ordering/HeaderInvoice";
import Pagination from "../../../../newStructure/component/navigation/Pagination";

const DEFAULT_MONTHS = 12;
const DEFAULT_PARAMS = {
    includeLines: false,
    pageNo: 1,
    pageSize: 10
}

const mapInvoices = (invoices) => {
    const initMap = {
        paid: [],
        due: [],
        passedDue: []
    }

    if(!invoices) return initMap;

    return invoices.reduce(
        (m,i) => {
            if(i.isPaid())
                m.paid.push(i);
            else if(i.isPassedDue())
                m.passedDue.push(i);
            else
                m.due.push(i);
            return m;
        },
        initMap
    )
}

const AccountLayoutInvoices = () => {

    const {
        loading,
        invoices: invoiceData,
        count,
        getInvoices
    } = useInvoices();

    const [ searchParams, setSearchParams ] = useState(DEFAULT_PARAMS);

    const searchForInvoices = () => {
        getInvoices(searchParams);
    }

    useValueChangeListener(searchForInvoices, [ searchParams ], []);

    const invoices = useMemo(() => invoiceData?.map(data => new Invoice(data)), [invoiceData]);
    const invoiceMap = useMemo(() => mapInvoices(invoices), [invoices]);

    const onRangeRequest = (startDate, endDate) => {
        if(!loading) {

            const idStartDate = `'${idempiereDate.getDateString(startDate)}'`;
            const idEndDate   = `'${idempiereDate.getDateString(endDate)}'`;

            setSearchParams({
                pageNo: 1,
                pageSize: 10,
                startDate: idStartDate,
                endDate: idEndDate
            });
        }
    }

    const onPageChange = (pageNo) => {
        setSearchParams({
            ...searchParams,
            pageNo: pageNo,
        })
    }

    return (
        <>
            <p style={{marginTop: "30px", marginBottom: "20px"}} className="page-h1">Invoices</p>
            <RangeSelector
                maxDates={DEFAULT_MONTHS}
                buttonText="Load Invoices"
                loading={loading}
                onRequest={onRangeRequest}
            />
            {loading &&
                <div className="account-view-list">
                    {[...Array(4).keys()].map((e,i) =>
                        <SkeletonDiv style={{height: "calc(1em + 10px)", width: "400px"}} key={i} />
                    )}
                </div>
            }
            {invoiceMap.passedDue.length > 0 &&
                <div className="account-view-list">
                    <p style={{marginTop: "32px", color: "var(--warning)"}} className="page-h2">Passed Due</p>
                    {invoiceMap.passedDue.map((e,i) =>
                        <HeaderInvoice
                            key={i}
                            invoice={e} />
                    )}
                </div>
            }
            {invoiceMap.due.length > 0 &&
                <div className="account-view-list">
                    <p style={{marginTop: "32px"}} className="page-h2">Due</p>
                    {invoiceMap.due.map((e,i) =>
                        <HeaderInvoice
                            key={i}
                            invoice={e} />
                    )}
                </div>
            }
            {invoiceMap.paid.length > 0 &&
                <div className="account-view-list">
                    <p style={{marginTop: "32px"}} className="page-h2">Paid</p>
                    {invoiceMap.paid.map((e,i) =>
                        <HeaderInvoice
                            key={i}
                            invoice={e} />
                    )}
                </div>
            }

            <Pagination
                currentPage={(searchParams?.pageNo)}
                loading={loading}
                lastPage={Math.ceil(count / 10)}
                onPageRequested={onPageChange}
            />
        </>
    )
}

export default AccountLayoutInvoices;
