import NavList from "./NavList";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import NavItem from "./NavItem";
import {useState} from "react";
import {categoryService} from "../../res/dataServices/category";
import {getCategories} from "../../redux/action/categoryActions";
import "./nav.css"
import {useMediaQuery} from "@mui/material";
import useLogOut from "../state/account/useLogOut";
import useUpdateEase from "../../newStructure/services/state/component/useUpdateEase";
import useSession from "../../newStructure/services/state/session/useSession";
import DropDownMenu from "../../newStructure/component/navigation/DropDownMenu";
import NavItemMenu from "./NavItemMenu";

const accountNav = [
    {name: "Users", nav: "/account/users",}
]

const vendorAccountNav = [
    {name: "Invoices", nav: "/account/vendor/invoices"},
    {name: "Payments", nav: "/account/vendor/payments"},
    {name: "Unpaid Invoices/ Upcoming Payments", nav: "/account/vendorInvoice"}
]

const vendorNav = [
    {name: "Account", children: [...accountNav, ...vendorAccountNav]},
    {name: "Manage Stock", children: [
            {name: "View Products", nav: "/vendor/products"},
            {name: "List New Product", nav: "/vendor/listing"}
        ]},
    {name: "Promotions", children: [{ name: "Create Banner Promotion", nav: "/vendor/promotion/bannerForm" }]}
]


const Nav = ({onHide}) => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logOut = useLogOut();

    const sessionContext = useSession();
    const session = sessionContext.sessionData;
    const { categoryMap, error, loading } = useSelector(
        state => state.session.category
    )
    const {
        isHovering,
        onMouseEnter,
        onMouseLeave
    } = useUpdateEase();

    if (!categoryMap && !loading && !error) {
        dispatch(getCategories({session}))
    }

    const categoryList = categoryService.getCategoryList(categoryMap);
    const onCategoryClick = (category) => {
        const noSubTabs = !category.children
        if(noSubTabs) {
            navigate("/category/" + category.id);
            setShowProducts(false)
            if(onHide) onHide();
        }
    }

    const onGeneralNavClick = (nav) => {
        if(nav.nav) navigate(nav.nav);
    }

    const smallScreen = useMediaQuery('(max-width:768px)')
    const [ showProducts, setShowProducts ] = useState(false);
    const handleProductClick = () => {
        if(smallScreen) {
            setShowProducts(!showProducts);
        } else {
            navigate("/category/");
        }
    }

    const currentNav = location.pathname.split("/")[1];
    const isCurrentLoc = (nav) => nav === currentNav;

    return (
        <>
            <div className="nav-close-button" onClick={onHide}>
                Close
            </div>
            <NavItem
                text="Products"
                displayState={showProducts ? 1 : 0}
                onClick={handleProductClick}
                isCurrentLoc={isCurrentLoc("category")}
                className={isHovering ? "hover" : null}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                disableClass
            >
                {smallScreen ?
                    <NavList
                        items={categoryList}
                        itemToText={e => e.name}
                        getItemChildren={e => e.children}
                        onItemClick={onCategoryClick}
                        parentSelected={showProducts}
                    />
                    :
                    <DropDownMenu
                        items={categoryList}
                        itemToText={e => e.name}
                        getChildren={e => e.children}
                        onItemSelected={onCategoryClick}
                    />
                }
            </NavItem>
            <NavItem
                text="Promotions"
                onClick={() => navigate("/promotion")}
                isCurrentLoc={isCurrentLoc("promotion")}
            />
            <NavItem
                text="Clearance"
                onClick={() => navigate("/clearance")}
                isCurrentLoc={isCurrentLoc("clearance")}
            />
            <NavItem
                text="Order Entry"
                onClick={() => navigate("/orderEntry")}
                isCurrentLoc={isCurrentLoc("orderEntry")}
            />
            {sessionContext.isVendor &&
                <NavItemMenu
                    text="Vendor Portal"
                >
                    <DropDownMenu
                        items={vendorNav}
                        itemToText={e => e.name}
                        getChildren={e => e.children}
                        onItemSelected={onGeneralNavClick}
                    />
                </NavItemMenu>
            }
            {smallScreen &&
                <NavItem text="Log Out" onClick={() => logOut()}/>
            }
            <div className="nav-buffer" />
        </>
    )

}

export default Nav;