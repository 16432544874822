import {useState} from "react";
import {useNavigate} from "react-router-dom";

const AccountNavItem = ({name, nav, selected, showChildren : defaultShowChildren, children}) => {

    const navigate = useNavigate();
    const [ showChildren, setShowChildren ] = useState(defaultShowChildren);

    const onNavItemClick = () => {
        if(children) {
            setShowChildren(!showChildren)
        } else {
            navigate(nav);
        }
    }

    const contentClass = "account-nav-item-content" + (selected ? " nav-item-selected": "");
    return (
        <div className="account-nav-item">
            <div className={contentClass} onClick={onNavItemClick}>
                <p>{name}</p>
            </div>
            {showChildren &&
                <div className="account-nav-item-children">
                    {children}
                </div>
            }
        </div>
    )

}

export default AccountNavItem;
