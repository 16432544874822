import AccountLayout from "./AccountLayout";
import AccountLayoutBilling from "../details/layout/AccountLayoutBilling";
import AccountLayoutLocations from "../details/layout/AccountLayoutLocations";
import AccountLayoutColleagues from "../details/layout/AccountLayoutColleagues";
import AccountLayoutCreditStatus from "../details/layout/AccountLayoutCreditStatus";
import AccountLayoutOrders from "../orders/layout/AccountLayoutOrders";
import AccountLayoutInvoices from "../orders/layout/AccountLayoutInvoices";
import AccountLayoutShipments from "../orders/layout/AccountLayoutShipments";
import AccountLayoutDetailOrder from "../orders/layout/AccountLayoutDetailOrder";
import AccountLayoutDetailInvoice from "../orders/layout/AccountLayoutDetailInvoice";
import AccountLayoutDetailShipment from "../orders/layout/AccountLayoutDetailShipment";
import AccountLayoutBackOrders from "../orders/layout/AccountLayoutBackOrders";
import AccountLayoutGetHelp from "../details/layout/AccountLayoutGetHelp";
import AccountLayoutHelpItem from "../details/layout/AccountLayoutHelpItem";
import AccountLayoutStatements from "../details/layout/AccountLayoutStatements";

const AccountView = ({accountViewParent, accountView, id}) => {

    if(accountViewParent === "details") {
        if(accountView === "colleagues") {
            return <AccountLayoutColleagues />

        } else if(accountView === "locations") {
            return <AccountLayoutLocations />

        } else if(accountView === "credit") {
            return <AccountLayoutCreditStatus />

        } else if(accountView === "billing") {
            return <AccountLayoutBilling />

        } else if(accountView === "statements") {
            return <AccountLayoutStatements />

        }
    } else if(accountViewParent === "ordering") {
        if(accountView === "orders") {
            if(id) {
                return <AccountLayoutDetailOrder id={id} />
            } else {
                return <AccountLayoutOrders />
            }
        } else if(accountView === "invoices") {
            if(id) {
                return <AccountLayoutDetailInvoice id={id} />
            } else {
                return <AccountLayoutInvoices />
            }
        } else if(accountView === "shipments") {
            if(id) {
                return <AccountLayoutDetailShipment id={id} />
            } else {
                return <AccountLayoutShipments />
            }
        } else if(accountView === "activeBackOrders") {
            return <AccountLayoutBackOrders />
        }
    } else if(accountViewParent === "help") {
        if(accountView === "desk") {
            if(id) {
                return <AccountLayoutHelpItem itemID={id} />
            } else {
                return <AccountLayoutGetHelp/>
            }
        }
    }

}

const AccountLayoutManager = ({accountViewParent, accountView, id}) => {

    const nav = "/account/" + accountViewParent + (accountView ? "/" + accountView : "");
    return (
        <AccountLayout nav={nav}>
            <AccountView
                id={id}
                accountViewParent={accountViewParent}
                accountView={accountView}
            />
        </AccountLayout>
    )
}

export default AccountLayoutManager;
