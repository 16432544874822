import PageVendor from "./PageVendor";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import FormDateRange from "../../form/FormDateRange";
import Form from "../../form/Form";
import FormButton from "../../form/FormButton";
import {useRef, useState} from "react";
import Dialog from "../../general/dialog/Dialog";
import Popup from "../../../../components/general/popup/Popup";
import usePayments from "../../../services/state/vendor/usePayments";
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import Payment from "../../vendor/invoice/Payment";
import Pagination from "../../navigation/Pagination";

const breadCrumb =[{name: "Home", nav: "/"}, {name: "Payment History", nav: "/account/vendor/payments", selected: "true"}];

const PagePaymentHistory = () => {

    const [ warning, setWarning ] = useState();
    const [ searchParams, setSearchParams ] = useState();
    const searchRef = useRef({});
    const { loading, payments, page, getPayments } = usePayments();

    const onSearchClick = () => {
        if(!searchRef.current.dates) {
            setWarning("Please select a date range.");
        } else if(!searchRef.current.dates.endDate) {
            setWarning("Please set the end of the date range");
        } else {

            const idStartDate = `'${idempiereDate.getDateString(searchRef.current.dates.startDate)}'`;
            const idEndDate   = `'${idempiereDate.getDateString(searchRef.current.dates.endDate)}'`;

            setSearchParams({
                pageNo: 1,
                pageSize: 10,
                startDate: idStartDate,
                endDate: idEndDate
            })
        }
    }

    const onPageChange = (pageNo) => {
        setSearchParams({...searchParams, pageNo});
    }

    useValueChangeListener(() => {
      if(searchParams) {
          getPayments(searchParams);
      }
    }, [searchParams]);

    return (
        <PageVendor>
            {warning &&
                <Popup>
                    <Dialog
                        title="Input Missing"
                        message={warning}
                        neutralButton={{name: "OK", onClick: () => setWarning(null)}}
                    />
                </Popup>
            }
            <div className="page-content page-min-height">
                <BreadcrumbNavigation>
                    {breadCrumb.map(crumb =>
                        <Breadcrumb name={crumb.name} selected={crumb.selected} nav={crumb.nav}/>
                    )}
                </BreadcrumbNavigation>
                <p className="page-h2" style={{textAlign: "left"}}>Payment History</p>
                <Form>
                    <FormDateRange
                        showMonthYearPicker
                        maxMonths={48}
                        name="Date Range"
                        onChange={dr => searchRef.current.dates = dr}
                    />
                    <FormButton
                        buttonClass={loading ? "loading" : ""}
                        gridColumn={1}
                        style={{marginTop: "20px"}}
                        onClick={onSearchClick}
                    >
                        Search
                    </FormButton>
                </Form>
                {payments && payments.length === 0 &&
                    <p className={"page-h4"} style={{textAlign: "left"}}>
                        No payments have been found for that date range
                    </p>
                }
                {payments && payments.map((p, i) =>
                    <Payment payment={p} key={i} />
                )}
                {payments && payments.length > 0 &&
                    <Pagination
                        isForm
                        loading={loading}
                        currentPage={searchParams?.pageNo}
                        lastPage={page}
                        onPageRequested={onPageChange}
                    />
                }
                <div style={{height: "200px"}} />
            </div>
        </PageVendor>
    )

}

export default PagePaymentHistory;