import PageLayout from "./PageLayout";
import {useParams} from "react-router-dom";
import AccountLayoutManager from "../account2/page/AccountLayoutManager";

const PageAccount = () => {

    const { accountViewParent, accountView, id } = useParams();

    return (
        <PageLayout
            requireSignIn
        >
            <AccountLayoutManager accountViewParent={accountViewParent} accountView={accountView} id={id} />
        </PageLayout>
    )

}

export default PageAccount;
