import PageLayout from "../page/PageLayout";
import "./about.css"

const REPLACE_THIS_WITH_SERVER_CALL = [
    {
        title: "HISTORY",
        message: "Sutherland Brothers Ltd was established in Wick in 1892 as a grocer shop by brothers Donald and Findlay Sutherland. It was situated in the High Street and was one of many traditional grocer shops in the busy market town, which was also a bustling herring port in the throes of a fishing boom.<br/>" +
            "<br/>" +
            "The fortunes of the business waxed and waned through two World Wars but remained basically unchanged. Deliveries were made my horse and cart, and message bike.<br/>" +
            "<br/>" +
            "Donald's son George took over the business after World War 2 and acquired part of a nearby store, from which wholesaling to other smaller shops began. Space became tight, and he managed to secure the entire two storey building. It was soon fully stocked and goods were moved around by wooden chutes from level to level.<br/>" +
            "<br/>" +
            "By 1960 sales had increased and a 5,000 sq ft steel and asbestos clad building on the other side of town was purchased and wholesaling began.<br/>" +
            "<br/>" +
            "Sales increased again, and wholesaling now dwarfed the original grocery business. The shop closed in 1990 and the wholesale business continues to expand at its current location. The current premises is a 40,000 sq ft custom built warehouse, in Wick Industrial Estate.<br/>"
    },
    {
        title: "OUR CUSTOMERS",
        message: "We supply customers in many different sectors - Retail, Foodservice and On Trade. Our current territory covers the Highlands and Islands, Moray and Aberdeenshire."
    },
    {
        title: "OPERATIONS",
        message:
            "The warehouse located in Wick Industrial Estate is the hub of our activites. Orders are captured by webstore, phone and email by 4pm each evening. Thereafter, loads are planned and picking commences. Voice controlled software is linked to our bespoke ERP system to pick and load all orders. Our fleet of efficient 18 tonne lorries are loaded and set off on their routes.<br/>" +
            "<br/>" +
            "We pride ourselves on our green credentials sourcing local products, minimising waste, proactively recycling both our and our customers cardboard and plastics whilst generating our own power. Two 10W windmills and photovoltaic panels are installed at the site making us self sufficient.<br/>"
    },
    {
        title: "BUYING GROUP",
        message: "Sutherland Brothers are part of Unitas Wholesale Ltd. "
    },
    {
        title: "PRODUCTS",
        message: "With over 5000 listed lines, our buying team are able to source a portfolio suited to the needs of your business.<br/>" +
            "<br/>" +
            "We list many local products and support smaller developing suppliers. This gives us a unique product portfolio for our customers with great promotional structures.<br/>" +
            "<br/>" +
            "As well as the majority of all brand leaders we have a vast range of own brand products. These include Lifestyle, Lifestyle Value and Caterers Kitchen<br/>"
    },
    {
        title: "AWARDS",
        message:
            "Sutherland Brothers have won many awards over the many years in business - these include<br/>" +
            "<br/>" +
            "    2019 SWA Best Licensed Operation - Runner Up<br/>" +
            "    2018 SWA Best Marketing Initiative - Highly Commended<br/>" +
            "    2017 SWA Best Licensed Operation - Runner Up<br/>" +
            "    2016 SWA Best Delivered Operation On-trade - Winner<br/>" +
            "    2016 SWA Corporate Social Responsibility - Runner Up<br/>" +
            "    2015 SWA Corporate Social Responsibility - Runner Up<br/>" +
            "    2015 SWA Best Delivered Operation On-trade - Runner Up<br/>" +
            "    2015 SWA Best Marketing Initiative - Highly Commended<br/>" +
            "    2014 SWA Best Delivered Operation Retail - Runner Up<br/>" +
            "    2014 SWA Corporate Social Responsibility -Winner<br/>" +
            "    2014 SWA Best Delivered Operation On-trade - Highly Commended<br/>" +
            "    2013 SWA Best Delivered Operation On-trade - Winner<br/>" +
            "    2013 Highland Business Awards - Caithness and Sutherland Business of the Year<br/>" +
            "    2012 SWA Best Delivered Operation On-trade - Winner<br/>" +
            "    2012 SWA Best Delivered Operation Retail - Highly Commended<br/>" +
            "    2012/2013 Landmark Catering Business Development Member of the Year<br/>" +
            "    2009/2010 Landmark Foodservice Own Brand Depot of the Yearp<br/>" +
            "    2008/2009 Landmark Foodservice Award<br/>" +
            "    2003/2004 Landmark Delivered Depot of the Year<br/>" +
            "    2003 SWA Champion of Champions<br/>" +
            "    2003 SWA Best Delivered Wholesaler -Winner<br/>" +
            "    2003 SWA Best Training Programme - Winner<br/>" +
            "    2001/2002 Landmark Delivered Depot of the Year<br/>" +
            "    2001/2002 Landmark Wholesaler of the Year<br/>" +
            "    2001 Landmark Special Achievement Award<br/>" +
            "    1998 Landmark Delivered Wholesaler - Runner Up<br/>" +
            "    1996 FWD Gold Medal - David Sutherland<br/>" +
            "<br/>"
    },
    {
        title: "BECOME A CUSTOMER?",
        message: "To join the Sutherland Brothers Customer Community and open an account please email sales@suthbros.co.uk "
    },
]

const PageAboutUs = () => {

    return (
        <PageLayout>
            <div className="about-body">
                <h2>About Us</h2>
                {REPLACE_THIS_WITH_SERVER_CALL.map((e,i) =>
                    <div className="about-us-item">
                        <h1>{e.title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: e.message }} />
                    </div>
                )}
            </div>
        </PageLayout>
    )
}

export default PageAboutUs;
