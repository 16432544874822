import {useState} from "react";
import {Button} from "../../../general/input/Button";
import Input from "../../../general/input/Input";

const ItemInput = ({title, defaultValue, changes, onChange : passedOnChange, changesKey}) => {

    const onChange = (event) => {
        const input = event.target.value.trim();
        const changesClone = {...changes};

        if(defaultValue === input) {
            delete changesClone[changesKey];
        } else {
            changesClone[changesKey] = input;
        }

        passedOnChange(changesClone);
    }

    return (
        <Input
            defaultValue={defaultValue}
            onChange={onChange}
        />
    )
}

const Item = ({title, children}) => {

    return (
        <div className="account-item">
            <p className="item-heading">{title}</p>
            {children}
        </div>
    )

}

const ColleagueInfo = ({colleague}) => {

    const [ changes, setChanges ] = useState({});
    const changesMade = changes && Object.keys(changes).length > 0;

    const onSave = () => {

    }

    const job = colleague["C_Job_ID"] ? colleague["C_Job_ID"].identifier : "";

    return (
        <div className="colleague-info-container">
            <Item title={"Name"}>
                <ItemInput
                    defaultValue={colleague.Name}
                    changesKey={"name"}
                    changes={changes}
                    onChange={setChanges}
                />
            </Item>
            <Item title={"Email"}>
                <ItemInput
                    defaultValue={colleague.EMail}
                    changesKey={"email"}
                    changes={changes}
                    onChange={setChanges}
                />
            </Item>
            <Item title="Position">
                <p className="item-info">{job ? job : "Not known"}</p>
            </Item>
            <div className="colleague-button-container">
                <Button
                    onClick={onSave}
                    className={changesMade && false ? "" : "inactive"}>
                    Save Changes
                </Button>
            </div>
        </div>
    )

}

export default ColleagueInfo;
