import FormItem from "./FormItem";
import RangeDatePicker from "../general/input/RangeDatePicker";

const FormDateRange = ({ name, maxMonths, maxWeeks, minDate, onChange, description, showMonthYearPicker }) => {

    return (
        <FormItem>
            <p className="input-form-sub-heading">{name}</p>
            {description && <p className="input-form-text">{description}</p>}
            <RangeDatePicker
                maxMonths={maxMonths}
                maxWeeks={maxWeeks}
                minDate={minDate}
                onChange={onChange}
                showMonthYearPicker={showMonthYearPicker}
            />
        </FormItem>
    )
}

export default FormDateRange;