import SkeletonDiv from "../../../general/loading/SkeletonDiv";

const HeaderSkeleton = () => {

    return (
        <div className="tile account-tile-loading">
            <div className="tile-header">
                <SkeletonDiv style={{
                    height:"4pt",
                    background:"none"
                }}/>
                <SkeletonDiv style={{
                    height:"1em",
                    margin:"4pt 4pt",
                    maxWidth: "100pt",
                }}/>
                <SkeletonDiv style={{
                    height:"1em",
                    margin:"4pt 4pt",
                    maxWidth: "100pt",
                }}/>
            </div>
            <div>
                <SkeletonDiv style={{
                    height:"1.6em",
                    margin:"6pt 4pt",
                    maxWidth: "200pt",
                }}/>
                <SkeletonDiv style={{
                    height:"1.6em",
                    margin:"6pt 4pt",
                    maxWidth: "min(400pt, 80vw)",
                }}/>
                <SkeletonDiv style={{
                    height:"6pt",
                    margin:"4pt 4pt",
                    maxWidth: "min(300pt, 60vw)",
                    background:"none"
                }}/>
            </div>
        </div>
    )
}

export default HeaderSkeleton;