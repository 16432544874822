import {Button} from "../../general/input/Button";
import {useNavigate} from "react-router-dom";

const HelpDeskInfo = ({ item }) => {

    const navigate = useNavigate();

    const link = item.Link;

    const handleRedirect = () => {
        navigate(link);
    }

    return (
        <div className="tile help-desk-item">
            <div className="tile-header account-tile-header-container">
                <p className="account-tile-header-p">{item.Name}</p>
            </div>
            <div className="help-desk-tile-body">
                <p>{item.Description}</p>
                {link &&
                    <Button onClick={handleRedirect}>Go</Button>
                }
            </div>
        </div>
    )
}

export default HelpDeskInfo;