import {format} from "date-fns";

const getDateString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}:${milliseconds}`;
}

const getPostString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

const getXMonthsAgo = (x) => {
    const today = new Date();
    const XMonthsAgo = new Date(today);
    XMonthsAgo.setMonth(today.getMonth() - x);

    return XMonthsAgo;
}

const getDate = (idDate) => {
    return new Date(idDate);
}

const compareDates = (thisDate, thatDate) => {

    const thisIdDate = getDate(thisDate);
    const thatIdDate = getDate(thatDate);

    return thisIdDate - thatIdDate;
}

const formatOrdinal = (number) => {
    if (number % 100 >= 11 && number % 100 <= 13) {
        return number + 'th';
    } else {
        switch (number % 10) {
            case 1:
                return number + 'st';
            case 2:
                return number + 'nd';
            case 3:
                return number + 'rd';
            default:
                return number + 'th';
        }
    }
}

const getDisplayDate = (date) => {
    // const dayName = format(date, 'EEEE');
    const monthName = format(date, 'MMMM');

    return formatOrdinal(date.getDate()) + " " + monthName + " " + date.getFullYear();
}

const getDeliveryDisplay = (date) => {

    const dayName = format(date, 'EEEE');
    const ordinal = formatOrdinal(date.getDate());
    const monthName = format(date, 'MMMM');

    return dayName + " " + ordinal + " " + monthName;
}

const getIdDisplayDate = (idDate) => {
    return getDisplayDate(getDate(idDate));
}


export const idempiereDate = {
    getDateString,
    getXMonthsAgo,
    getDate,
    compareDates,
    getDisplayDate,
    getIdDisplayDate,
    getDeliveryDisplay,
    getPostString
}