import HelpDeskInfo from "./HelpDeskInfo";
import HelpDeskRequest from "./HelpDeskRequest";

const HelpDeskItem = ({item}) => {
    // return nothing if the item hasn't loaded yet
    if(!item) return;

    const request = item["R_RequestType_ID"]
    if(request) {
        return <HelpDeskRequest item={item} />
    } else {
        return <HelpDeskInfo item={item} />
    }

}

export default HelpDeskItem;