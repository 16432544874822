import HelpDeskCategory from "./HelpDeskCategory";

const HelpDeskOverview = ({helpDeskItems}) => {

    return (
        <>
            <p className="account-heading-large">Help Desk</p>
            <div className="help-desk-container">
                {helpDeskItems && helpDeskItems.map((e,i) =>
                    <HelpDeskCategory key={i} category={e} />
                )}
            </div>
        </>
    )
}

export default HelpDeskOverview;