
import "./../style/account.css"
import "./../style/account-component.css"
import "./../style/account-ordering.css"
import AccountNav from "../nav/AccountNav";

const AccountLayout = ({nav, children}) => {

    return (
        <div className="account-body-2">
            <AccountNav currentPage={nav} />
            <div className="account-content">
                {children}
                <div style={{minHeight: "20pt"}} />
            </div>
        </div>
    )

}

export default AccountLayout;