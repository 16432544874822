import PageVendor from "./PageVendor";
import NewProductForm from "../../vendor/product/NewProductForm";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";

const breadCrumb =[{name: "Home", nav: "/"}, {name: "Product Listing", nav: "/vendor/listing", selected: "true"}];

const PageProductListing = () => {


    return(
        <PageVendor>
            <div className="page-content">
                <BreadcrumbNavigation>
                    {breadCrumb.map(crumb =>
                        <Breadcrumb name={crumb.name} selected={crumb.selected} nav={crumb.nav} />
                    )}
                </BreadcrumbNavigation>
                <p className="page-h2" style={{textAlign: "left"}}>Product Listing Form</p>
                <NewProductForm />
            </div>
        </PageVendor>
    )

}

export default PageProductListing;