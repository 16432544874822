import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";

const clearanceRequest = (orgID) => {

    const builder = {};

    const req = request.model(api.MODEL.DISCOUNT)
        .select("M_Product_ID", "available")
        .filter(
            condition.eq("isActive", "true"),
            condition.greaterThan("available", "0"),
            condition.eq("AD_Org_ID", orgID)
        )

    builder.sendRequest = async (session) => {
        const results = await api.getAllRequestPages(req, session);
        return { items: results }
    }

    return builder;
}

export default clearanceRequest;