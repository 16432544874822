import PageVendor from "./PageVendor";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import BannerForm from "../../vendor/promotion/BannerForm";

const breadCrumb =[{name: "Home", nav: "/"}, {name: "Banner Form", nav: "/vendor/promo/bannerForm", selected: "true"}];

const PageCreateBanner = () => {

    return (
        <PageVendor>
            <div className="page-content">
                <BreadcrumbNavigation>
                    {breadCrumb.map(crumb =>
                        <Breadcrumb name={crumb.name} selected={crumb.selected} nav={crumb.nav}/>
                    )}
                </BreadcrumbNavigation>
                <p className="page-h2" style={{textAlign: "left"}}>Banner Form</p>
                <BannerForm />
            </div>
        </PageVendor>
    )
}

export default PageCreateBanner;