const PRODUCT_IMG = "/assets/products.svg";
const CATEGORY_IMG = "/assets/category.svg";

const SearchItem = ({ item, children, showAsHover, handleClick, onMouseEnter, showHover }) => {

    const isProduct = item.isProduct;
    const imgSrc = isProduct ? PRODUCT_IMG : CATEGORY_IMG;
    const name = isProduct ? item.name : item.product_cat_name[0];

    const nestedItem = Boolean(children);
    const itemClassName = "search-item"
        + (nestedItem && showHover ? " search-item-hide-able" : "");
    const containerClassName = "search-item-container"
        + (showAsHover ? " search-item-hover" : "");

    return (
        <div
            className={containerClassName}
            onClick={handleClick}
            onMouseEnter={onMouseEnter}
        >
            <div className={itemClassName}>
                <img alt="" src={imgSrc}/>
                <p>{name}</p>
            </div>
            {nestedItem && showHover &&
                <div className="search-item-hidden">
                    {children}
                </div>
            }
        </div>
    )
}

export default SearchItem;