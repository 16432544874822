import useShipments from "../../../../newStructure/services/state/account/useShipments";
import useValueChangeListener from "../../../../newStructure/services/state/general/useValueChangeListener";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import SkeletonDiv from "../../../general/loading/SkeletonDiv";
import {request} from "../../../../res/rest/restRequest";
import {api} from "../../../../res/rest/api";
import {env} from "../../../../res/config/env";
import usePrint from "../../../general/output/usePrint";
import LineShipment from "../ordering/LineShipment";
import {reportError} from "../../../../redux/slice/errorReportSlice";

const reportAnError = (dispatch, e) => {
    dispatch(reportError({
        code: "shipmentPrintError",
        error: e && e.message ? e.message : "failed to fetch report"
    }))
}

const AccountLayoutDetailShipment = ({id}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        loading,
        shipments,
        getShipments
    } = useShipments();

    const { loading: loadingPDF, fetchAndPrintIt } = usePrint({
        url: request.print(api.MODEL.M_INOUT, id).buildRequest(env.API_URL),
        onError: (e) => reportAnError(dispatch, e)
    })

    useValueChangeListener(() => getShipments({ids: [id], includeLines: true}), [id], []);

    const shipment = shipments && shipments.length > 0 ? shipments[0] : null;
    const lines = shipment?.m_inoutline;

    console.log(shipment)

    return (
        <div className="account-order-detail-view">
            <div className="account-order-detail-header">
                <p className="clickable-text" onClick={() => navigate("/account/ordering/shipments")}>Back To Shipments</p>
                {loading ?
                    <SkeletonDiv style={{height:"2.4em", width:"min(300pt, 40vw)", marginBottom: "10pt"}}/> :
                    <p className="account-header-2">Shipment: {shipment ? shipment.DocumentNo : ""}</p>
                }
                <div className="account-order-detail-buttons">
                    {loadingPDF ?
                        <SkeletonDiv style={{height: "calc(1em + 10px)", width: "10em"}} /> :
                        <p className="clickable-text"
                           onClick={fetchAndPrintIt}
                           style={{margin: "4px 0", color: "var(--secondary)"}}>
                            Print Order
                        </p>
                    }
                </div>

                <div className="account-order-detail-lines">
                    <p style={{marginTop: "32px", marginBottom: "12px"}} className="page-h3">Shipment Lines</p>
                    <div className="account-order-lines-display">
                        {loading ?
                            [...Array(4).keys()].map((e,i) =>
                                <SkeletonDiv style={{height: "2em", width: "400px"}} key={i}/>) :
                            (!loading && lines) &&
                            lines.map((e,i) =>
                                <LineShipment
                                    key={i}
                                    shipmentLine={e}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

// const formatData = (activeShipments, ordering) => {
//     if(ordering.shipments && Object.values(ordering.shipments).length > 0) {
//         const shipment = Object.values(ordering.shipments)[0];
//         const orders = ordering.orders;
//         const invoices = ordering.invoices;
//         return {shipment, orders, invoices};
//     } else if(activeShipments && activeShipments.shipments.length > 0) {
//         const shipment = activeShipments.shipments[0];
//         const orders = activeShipments.orders;
//         const invoices = activeShipments.invoices;
//         return {shipment, orders, invoices}
//     } else {
//         return {}
//     }
// }
//
// const AccountLayoutDetailShipment = ({id}) => {
//
//     const [ reloaded, setReloaded ] = useState(false);
//
//     const navigate = useNavigate();
//
//     const dispatch = useDispatch();
//     const { loading: loadingPDF, fetchAndPrintIt } = usePrint({
//         url: request.print(api.MODEL.M_INOUT, id).buildRequest(env.API_URL),
//         onError: (e) => dispatch(reportError({
//             code: "shipmentPrintError",
//             error: e && e.message ? e.message : "failed to fetch report"
//         }))
//     })
//
//     const {
//         activeShipments,
//         ordering,
//         loading,
//         getAShipment,
//         getShipmentLines
//     } = useShipments();
//
//     if(!reloaded) {
//         setReloaded(true);
//         getAShipment(id);
//         getShipmentLines(id);
//     }
//
//     const { shipment, orders, invoices } = formatData(activeShipments, ordering);
//     const showLoading = !reloaded || loading.activeShipments || loading.shipments;
//     const linkedOrders = orders ? Object.values(orders) : [];
//     const linkedInvoices = invoices ? Object.values(invoices) : [];
//
//     const getLinkedOrder = (shipmentLine) => {
//         const orderID = shipmentLine["C_Order_ID"];
//         if(orderID && orders ){
//             return orders[orderID.id];
//         }
//     }
//     const getLinkedInvoices = (shipmentLine) => {
//         if(!shipmentLine.lineInvoices || !invoices) return;
//
//         const invoiceIDs = idempiereType.parseArray(shipmentLine.lineInvoices);
//         const linked = [];
//         for(const id of invoiceIDs) {
//             const invoice = invoices[id];
//             if(invoice) linked.push(invoice);
//         }
//         return linked;
//     }
//
//     return (
//         <div className="account-order-detail-view">
//             <div className="account-order-detail-header">
//                 <p className="clickable-text" onClick={() => navigate("/account/ordering/shipments")}>Back To Shipments</p>
//                 {loading ?
//                     <SkeletonDiv style={{height:"2.4em", width:"min(300pt, 40vw)", marginBottom: "10pt"}}/> :
//                     <p className="account-header-2">Shipment: {shipment ? shipment.DocumentNo : ""}</p>
//                 }
//                 <div className="account-order-detail-buttons">
//                     <Button className={loadingPDF ? "button-loading" : null} onClick={fetchAndPrintIt}>Print Shipment</Button>
//                 </div>
//             </div>
//
//             <div className="account-order-detail-links">
//                 <Linked
//                     links={linkedOrders}
//                     header={"Linked Orders"}
//                     loading={loading.orders}
//                     toComponent={(e,i) => <CompactOrder key={i} order={e} />}
//                 />
//                 <Linked
//                     links={linkedInvoices}
//                     header={"Linked Invoices"}
//                     loading={loading.invoices}
//                     toComponent={(e,i) => <CompactInvoice key={i} invoice={e} />}
//                 />
//             </div>
//
//             <div className="account-order-detail-lines">
//                 <p className="account-header-2">Shipment Lines</p>
//                 <div className="account-order-lines-display">
//                     {showLoading ?
//                         [...Array(4).keys()].map((e,i) =>
//                             <HeaderSkeleton key={i}/>) :
//                         shipment["web_ordering_shipmentLine_v"]?.map((e,i) =>
//                             <LineShipment
//                                 key={i}
//                                 shipmentLine={e}
//                                 order={getLinkedOrder(e)}
//                                 invoices={getLinkedInvoices(e)}
//                             />
//                         )
//                     }
//                 </div>
//             </div>
//
//         </div>
//     )
// }

export default AccountLayoutDetailShipment;
