import PageLayout from "../page/PageLayout";

const REPLACE_THIS_WITH_SERVER_CALL = [

    {
        title: "SETTLEMENT TERMS",
        message: "Payment due by Direct Debit on the 7th and 21st of each month\n" +
            "Overdue Accounts subject to 2% Credit Charge"
    },
    {
        title: "RETENTION OF TITLE",
        message: "The legal title to goods supplied by the company shall remain \n" +
            "with the company until full payment is received."
    },
    {
        title: "CLAIMS",
        message: "All goods should be examined immediately on delivery. No claim\n" +
            "For Loss will be entertained, unless written notice thereof is made\n" +
            "BEFORE THREE DAYS ELAPSE<br/>" +
            "NOTE: '*' Denotes Special Offer Price "
    },

]

const PageTermsAndConditions = () => {

    return (
        <PageLayout>
            <div className="about-body">
                <h2>Terms and conditions</h2>
                {REPLACE_THIS_WITH_SERVER_CALL.map((e,i) =>
                    <div className="about-us-item">
                        <h1>{e.title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: e.message }} />
                    </div>
                )}
            </div>
        </PageLayout>
    )
}

export default PageTermsAndConditions;