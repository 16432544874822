import useAuthRequest from "../request/useAuthRequest";
import invoiceRequest from "../../rest/request/account/invoiceRequest";

const useInvoices = () => {

    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    const getInvoices = ({ ids, startDate, endDate, pageNo = 1, pageSize = 10, includeLines, isPaid,
                             includeAllocation, oldestFirst, isSoTrx=true, docType, includeOpenBalance }) => {
        const req = invoiceRequest()
            .top(pageSize)
            .isSoTrx(isSoTrx)
            .skip(pageSize * (pageNo - 1));

        if(docType)
            req.docType(docType);

        if(includeOpenBalance)
            req.includeOpenBalance();

        if(startDate || endDate)
            req.forDateRange(startDate, endDate);

        if(includeLines) {
            req.includeLines();
            req.includeProducts();
        }

        if(isPaid != null) {
            req.isPaid(isPaid);
        }

        if(ids)
            req.forIDs(...ids);

        if(oldestFirst)
            req.oldestFirst();

        if(includeAllocation)
            req.includeAllocation();

        sendIt(req.sendIt)
    }

    return {
        loading,
        error,
        invoices: result?.records,
        count: result?.count,
        page: result?.page,
        getInvoices
    }
}

export default useInvoices;