import HeaderShipment from "../ordering/HeaderShipment";
import {useState} from "react";
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import RangeSelector from "../../../account/component/RangeSelector";
import useShipments from "../../../../newStructure/services/state/account/useShipments";
import useValueChangeListener from "../../../../newStructure/services/state/general/useValueChangeListener";
import SkeletonDiv from "../../../general/loading/SkeletonDiv";
import Pagination from "../../../../newStructure/component/navigation/Pagination";

const DEFAULT_MONTHS = 12;
const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_SEARCH_PARAMS = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE
}

const AccountLayoutShipments = () => {

    const {
        loading,
        shipments,
        count,
        getShipments
    } = useShipments();

    const [ searchParams, setSearchParams ] = useState(DEFAULT_SEARCH_PARAMS);

    const getTheShipments = () => {
        getShipments(searchParams);
    }

    useValueChangeListener(getTheShipments, [ searchParams ], []);
    // useValueChangeListener(() => getOutForDelivery({outForDelivery: true}), [1], []);

    const onRangeRequest = (startDate, endDate) => {
        if(!loading) {

            const idStartDate = `'${idempiereDate.getDateString(startDate)}'`;
            const idEndDate   = `'${idempiereDate.getDateString(endDate)}'`;

            setSearchParams({
                startDate: idStartDate,
                endDate: idEndDate,
                pageNo: 1,
                pageSize: DEFAULT_PAGE_SIZE,
            })
        }
    }

    const onPageChange = (pageNo) => {
        setSearchParams({
            ...searchParams,
            pageNo: pageNo,
        })
    }

    return (
        <>
            <p style={{marginTop: "30px", marginBottom: "20px"}} className="page-h1">Shipments</p>
            <RangeSelector
                maxDates={DEFAULT_MONTHS}
                buttonText="Load Shipments"
                loading={loading}
                onRequest={onRangeRequest}
            />
            <p style={{marginTop: "32px"}} className="page-h2">Shipments</p>
            {loading &&
                <div className="account-view-list">
                    {[...Array(4).keys()].map((e,i) =>
                        <SkeletonDiv style={{height: "calc(1em + 10px)", width: "400px"}} key={i} />
                    )}
                </div>
            }
            {(!loading && shipments?.length > 0) &&
                <div className="account-view-list">
                    {shipments.map((e,i) =>
                        <HeaderShipment
                            key={i}
                            shipment={e}
                        />
                    )}
                </div>
            }
            {(!loading && !(shipments?.length > 0)) &&
                <p style={{marginTop: "12px"}}
                   className="page-h4">
                    Sorry! No shipments have been found.
                </p>
            }

            <Pagination
                currentPage={(searchParams?.pageNo)}
                loading={loading}
                lastPage={Math.ceil(count / DEFAULT_PAGE_SIZE)}
                onPageRequested={onPageChange}
            />
        </>
    )

}

export default AccountLayoutShipments;
