import {range} from "../../../../res/dataServices/range";
import SkeletonDiv from "../../../../components/general/loading/SkeletonDiv";
import usePayments from "../../../services/state/vendor/usePayments";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import Payment from "./Payment";

const now          = new Date();
const MONTHS       = 10;
const END_DATE     = "'" + idempiereDate.getDateString(now) + "'";
const START_DATE   ="'" +  idempiereDate.getDateString(new Date(now.setMonth(now.getMonth() - MONTHS))) + "'";

const RecentPaymentList = () => {

    const { loading, payments, getPayments } = usePayments();
    useValueChangeListener(() => {
        getPayments({
            startDate: START_DATE,
            endDate: END_DATE,
            pageSize: 20,
            pageNo: 1
        })
    }, [""], []);

    return (
        <>
            {loading && range(5).map((i) => <SkeletonDiv key={i} style={{width: "600px", height: "20px", margin: "20px 0"}} />)}
            {!loading && (!payments || payments.length === 0)  && <p>No recent payments have been found.</p>}
            {payments && payments.map((p,i) =>
                <Payment key={i} payment={p} />
            )}
        </>
    )

}

export default RecentPaymentList;