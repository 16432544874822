import PageLayout from "../../../../components/page/PageLayout";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import useColleagues from "../../../services/state/account/useColleagues";
import {range} from "../../../../res/dataServices/range";
import SkeletonDiv from "../../../../components/general/loading/SkeletonDiv";
import Popup from "../../../../components/general/popup/Popup";
import Dialog from "../../general/dialog/Dialog";
import User from "../../account/user/User";
import {useMemo} from "react";


const breadCrumb =[{name: "Home", nav: "/"}, {name: "Users", nav: "/account/users", selected: "true"}];


const PageUser = () => {

    const { loading, colleagues, interestAreas, updating, updateUser } = useColleagues();

    const orderedUsers = useMemo(() => {
        if(!colleagues) return [];
        const ordered = [...colleagues];
        ordered.sort((a,b) => a.id - b.id);
        return ordered;
    }, [colleagues]);

    return (
        <PageLayout>
            <div className="page-content">
                <BreadcrumbNavigation>
                    {breadCrumb.map(crumb =>
                        <Breadcrumb name={crumb.name} selected={crumb.selected} nav={crumb.nav}/>
                    )}
                </BreadcrumbNavigation>
                {updating &&
                    <Popup>
                        <Dialog
                            title={"Updating"}
                            message={"Updating please wait"}
                            showLoading={true}
                        />
                    </Popup>
                }
                <p className="page-h2" style={{textAlign: "left"}}>Users</p>
                {loading && range(5).map((r, i) =>
                    <SkeletonDiv key={i} style={{height: "20px", width: "600px", margin: "10px 0"}}/>
                )}
                {orderedUsers.map((c,i) =>
                    <User id={i} user={c} interestAreas={interestAreas} updateUser={updateUser} />
                )}
            </div>
        </PageLayout>
    )
}

export default PageUser;
