import {condition, request} from "../../../../../res/rest/restRequest";
import axios from "axios";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";

const paymentRequest = () => {

    const builder = {};
    const req = request.model("C_Payment")
        .select("C_DocType_ID", "DateAcct", "C_Invoice_ID", "PayAmt")
        .expand(request.subQueryList("C_AllocationLine")
            .expand(request.subQueryList("C_Invoice_ID").select("DocumentNo", "C_DocType_ID")))
        .filter(condition.in("DocStatus", "'CO'"))
        .hostURL(env.API_URL);

    builder.top = (top) => {
        req.top(top);
        return builder;
    }

    builder.skip = (skip) => {
        req.skip(skip);
        return builder;
    }

    builder.forIDs = (...ids) => {
        req.filter(condition.in("C_Invoice_ID", ...ids));
    }

    builder.forDateRange = (startDate, endDate) => {
        if(startDate) {
            req.filter(condition.greaterThan("created", startDate));
        }
        if(endDate) {
            req.filter(condition.lessThan("created", endDate));
        }
    }

    builder.sendIt = async (session) => {
        req.filter(condition.eq("c_bpartner_id", session.bPartnerID))
        const resp = await axios.get(req.buildRequest(), api.auth(session));
        return {
            records: resp.data.records,
            page: resp.data["page-count"],
            count: resp.data["row-count"]
        }
    }

    return builder;
}

export default paymentRequest;