import React from "react";
import {format} from "date-fns";
import GoogleMapReact from 'google-map-react';
import {shipmentService} from "../../../../res/dataServices/shipmentService";
import {useNavigate} from "react-router-dom";

const DriverLocation = ({lat, lng}) => {
    const defaultProps = {
        center: {
            lat, lng
        },
        zoom: 12
    };

    return (
        <div style={{ height: '200pt', width: '200pt' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                <div style={{height: "10pt", width: "10pt", background: "red"}}
                     lat={lat}
                     lng={lng}
                />
            </GoogleMapReact>
        </div>
    );
}

const ActiveDeliveryInfo = ({ shipment, lastSeen }) => {
    if(!shipmentService.isOutForDelivery(shipment)) return null;

    const arrivalMessage = shipmentService.getExpectedArrivalMessage(shipment);
    const dropsAhead = Number(shipment.dropsAhead);

    return (
        <>
            <p className="account-tile-content-b">{arrivalMessage}</p>
            {dropsAhead > 0 ?
                <p className="account-tile-content-b">Your order is
                    <span style={{color: "var(--primary)", fontWeight: "bold"}}> {dropsAhead}</span> stops away
                </p>
                : <p className="account-tile-content-b">Your delivery is next!</p>
            }
        </>
    )

}

const HeaderShipment = ({ shipment, lastSeen }) => {

    const navigate = useNavigate();
    const onSeeFullDetails = () => {
        navigate("/account/ordering/shipments/" + shipment.id);
    }

    const documentNo = shipment.DocumentNo;
    const deliveryDate = format(shipmentService.getDeliveryDate(shipment), "dd/MM/yy")
    const locationName = shipment["C_BPartner_Location_ID"].identifier;
    shipmentService.getDeliveryDate(shipment);

    const statusMsg = shipmentService.getStatusMessage(shipment);

    // const isDelivered = !shipmentService.isInProgress(shipment);
    const ofd = shipmentService.isOutForDelivery(shipment);
    const displayDriverLocation = ofd && shipment.latitude && shipment.longitude;
    const bodyClassName = displayDriverLocation ? "shipment-tile-body" : "account-tile-body"

    return (
        <div className="tile shipment-tile">
            <div className="tile-header account-tile-header-container">
                <p className="account-tile-b" style={{color: "var(--primary)"}}>{documentNo}</p>
                <p className="account-tile-b">Delivered: {deliveryDate}</p>
                <p className="account-tile-b">{locationName}</p>
            </div>
            <div className={bodyClassName}>
                <div className="account-tile-content">
                    <p className="account-tile-content-h">Status</p>
                    <p className="account-tile-content-b">{statusMsg}</p>
                    {lastSeen &&
                        <ActiveDeliveryInfo shipment={shipment} />
                    }
                </div>
                <div className="account-tile-action">
                    <p style={{marginTop: "auto", marginBottom: "0", color: "var(--secondary)"}}
                       className="clickable-text" onClick={onSeeFullDetails}>
                        See Full Details
                    </p>
                </div>
                {displayDriverLocation &&
                    <div className="delivery-tile-map-container">
                        <DriverLocation lat={shipment.latitude} lng={shipment.longitude} />
                    </div>
                }
            </div>
        </div>
    )

}

export default HeaderShipment;