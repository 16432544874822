import '../style/form/form.css'

const Form = ({children}) => {

    return (
        <div className="input-form">
            {children}
        </div>
    )
}

export default Form;
