import RangeMonthSelector from "../../general/input/RangeMonthSelector";
import {useState} from "react";
import {Button} from "../../general/input/Button";

const RangeSelector = ({maxDates, loading, onRequest, buttonText}) => {

    const [ dates, setDate ] = useState({});

    const onButtonClick = () => {
        if(dates.endDate) {
            const lastDayInTheMonth = new Date(dates.endDate);
            lastDayInTheMonth.setMonth(lastDayInTheMonth.getMonth() + 1, 1);
            lastDayInTheMonth.setDate(lastDayInTheMonth.getDate() - 1);

            if(onRequest) {
                onRequest(dates.startDate, lastDayInTheMonth);
            }
        }
    }

    return (
        <RangeMonthSelector
            maxMonths={maxDates}
            startDate={dates.startDate}
            endDate={dates.endDate}
            onChange={setDate}
        >
            <Button className={
                loading ? "button-loading" :
                    dates.endDate ? "" : "inactive"
            } onClick={onButtonClick}>{buttonText}</Button>
        </RangeMonthSelector>
    )
}

export default RangeSelector;