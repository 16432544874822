import PageVendor from "./PageVendor";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import InvoiceDetail from "../../vendor/invoice/InvoiceDetail";
import {useParams} from "react-router-dom";

const breadCrumb =[{name: "Home", nav: "/"}, {name: "Unpaid Invoices", nav: "/account/vendorInvoice", selected: false}, {name: "Invoice", selected: true}];

const PageInvoiceDetail = () => {

    const { invoiceID } = useParams();

    return (
        <PageVendor>
            <div className="page-content page-min-height">
                <BreadcrumbNavigation>
                    {breadCrumb.map(crumb =>
                        <Breadcrumb name={crumb.name} selected={crumb.selected} nav={crumb.nav}/>
                    )}
                </BreadcrumbNavigation>
                <InvoiceDetail invoiceID={invoiceID}/>
                <div style={{height: "200px"}}/>
            </div>
        </PageVendor>
    )
}

export default PageInvoiceDetail;