
const ATTRIBUTE_SET = {
    type: "SSL",
    id: 111,
    name: "Order By",
    seqNo: 10
}

const OPTION_RELEVANCE = {
    attributeID: 111,
    name: "Relevance",
    seqNo: 0,
}

const OPTION_A_Z = {
    attributeID: 111,
    name: "A to Z",
    seqNo: 10,
}

const OPTION_Z_A = {
    attributeID: 111,
    name: "Z to A",
    seqNo: 20,
}




const OrderBy = {
    ATTRIBUTE_SET,
    OPTION_RELEVANCE,
    OPTION_A_Z,
    OPTION_Z_A,
    FILTER: {
        attribute: ATTRIBUTE_SET,
        options: [
            OPTION_RELEVANCE,
            OPTION_A_Z,
            OPTION_Z_A
        ]
    }
}

export default OrderBy;