import PageVendor from "./PageVendor";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import UnpaidInvoiceList from "../../vendor/invoice/UnpaidInvoiceList";
import RecentPaymentList from "../../vendor/invoice/RecentPaymentList";

const breadCrumb =[{name: "Home", nav: "/"}, {name: "Unpaid Invoices", nav: "/account/vendorInvoice", selected: "true"}];

const PageVendorInvoiceList = () => {

    return (
        <PageVendor>
            <div className="page-content page-min-height">
                <BreadcrumbNavigation>
                    {breadCrumb.map(crumb =>
                        <Breadcrumb name={crumb.name} selected={crumb.selected} nav={crumb.nav}/>
                    )}
                </BreadcrumbNavigation>
                <p className="page-h2" style={{textAlign: "left"}}>Unpaid Invoices</p>
                <div>
                    <UnpaidInvoiceList/>
                </div>
                <p className="page-h2" style={{textAlign: "left", marginTop: "40px"}}>Recent Payments</p>
                <div>
                    <RecentPaymentList/>
                </div>
                <div style={{height: "200px"}} />
            </div>
        </PageVendor>
    );
}

export default PageVendorInvoiceList;