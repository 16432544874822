import useInvoices from "../../../services/state/account/useInvoices";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import {range} from "../../../../res/dataServices/range";
import SkeletonDiv from "../../../../components/general/loading/SkeletonDiv";
import './invoice.css'
import {useMemo} from "react";
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import {format} from "date-fns";

const KeyAndVal = ({h, val, r}) => {

    return (
        <div>
            <p className="inv-h">{h}</p>
            <p className={"inv-v" + (r ? " r" : "")}>{val}</p>
        </div>
    )
}

const InvoiceDetail = ({ invoiceID }) => {

    const { loading, invoices, getInvoices } = useInvoices();
    useValueChangeListener(() => {
        getInvoices({
            ids: [invoiceID],
            isSoTrx: false,
            includeOpenBalance: true,
            includeLines: true,
            includeAllocation: true
        })
    }, [invoiceID], []);

    const getDisplayAmount = (amount) => {
        return amount >= 0 ? ("£" + amount.toFixed(2)) : ("-£" + (-amount).toFixed(2))
    }

    const invoice = invoices ? invoices[0] : null;
    const openBalance = invoice?.web_invoice_open_balance_v[0]?.openbalance;
    const invoiceLines = useMemo(() => {
        if(invoice) {
            const lines = [...invoice.c_invoiceLine];
            lines.sort((a,b) => a.Line - b.Line)
            return lines;
        }
        return [];
    }, [invoice])
    const payments = useMemo(() => {
        if(invoice?.C_AllocationLine && invoice.C_AllocationLine.length > 0) {
            return invoice.C_AllocationLine;
        }
        return null;
    }, [invoice]);

    return (
        <>
            {loading && range(5).map((i) => <SkeletonDiv key={i} style={{width: "600px", height: "20px", margin: "20px 0"}} />)}
            {invoice &&
                <div className="invoice-detail-container">
                    <p className="page-h2">Invoice {invoice.DocumentNo}</p>
                    <p className="page-h4" style={{marginBottom: "20px"}}>{invoice.DocBaseType?.identifier}</p>
                    <div style={{maxWidth: "600px", marginBottom: "30px"}} className="unpaid-invoice-vals">
                        <KeyAndVal h="Invoice Number" val={invoice.DocumentNo}/>
                        <KeyAndVal h="Due Date" val={invoice.DueDate}/>
                        <KeyAndVal h="Invoice Total" val={getDisplayAmount(invoice.GrandTotal)} r/>
                        <KeyAndVal h="Open Balance" val={getDisplayAmount(openBalance)} r/>
                    </div>
                    <p className="page-h3">Lines</p>
                    <div className="inv-line-grid product">
                        <p className="inv-h">Line No.</p>
                        <p className="inv-h">Product</p>
                        <p className="inv-h">Quantity</p>
                        <p className="inv-h">Line Total</p>
                        {invoiceLines?.map((line, i) => (
                            <>
                                <p key={(i * 10) + 1} className="inv-v">{line.Line}</p>
                                <p key={(i * 10) + 2} className="inv-v">{line.M_Product_ID?.Name}</p>
                                <p key={(i * 10) + 3} className="inv-v r">{line.QtyEntered}</p>
                                <p key={(i * 10) + 4} className="inv-v r">{getDisplayAmount(line.LineNetAmt)}</p>
                            </>
                        ))}
                    </div>
                    <p className="page-h3">Payments</p>
                    {payments &&
                        <div className="inv-line-grid payment">
                            <p className="inv-h">Payment Date</p>
                            <p className="inv-h">Amount</p>
                            {payments.map((p,i) => (
                                <>
                                    <p key={(i*10) + 1} className="inv-v">{format(idempiereDate.getDate(p.Created), "dd-MM-yy")}</p>
                                    <p key={(i*10) + 2} className="inv-v">{getDisplayAmount(p.Amount)}</p>
                                </>
                            ))}
                        </div>
                    }
                    {!payments &&
                        <p>No payments have been found for this invoice.</p>
                    }
                </div>
            }
        </>
    )
}

export default InvoiceDetail;