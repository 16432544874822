import './address.css'
import AddressDisplay from "../../account2/details/address/AddressDisplay";
import {Button} from "../../general/input/Button";

const AddressMultiOptions = ({ addresses, onSelect, onUpdate, onRemove }) => {

    const handleSelect = (location) => () => {
        onSelect(location)
    }

    return (
        <>
            {addresses ? addresses.map((e,i) =>
                <AddressDisplay location={e} key={i}>
                    <Button onClick={handleSelect(e)}>Select</Button>
                </AddressDisplay>
            ) :
                <></>
            }
        </>
    )

}

export default AddressMultiOptions;