
class Invoice {

    constructor(data) {
        this.data = data;
    }

    isPassedDue() {
        if(!this.isPaid()) {
            const currentDate = new Date();
            currentDate.setHours(0,0,0,0);
            return this.getDueDate() < currentDate;
        }
    }

    isPaid() {
        return this.data.IsPaid;
    }

    getDueDate() {
        return new Date(this.data.DueData);
    }

    getIssued() {
        return this.data.DateInvoiced;
    }

    getGrandTotal() {
        return this.data.GrandTotal;
    }

    getDocumentNo() {
        return this.data.DocumentNo;
    }

    getDisplayStatus() {
        if(this.isPaid()) {
            return "Paid";
        } else if(this.isPassedDue()) {
            return "Passed Due";
        } else {
            return "Due"
        }
    }

    getID() {
        return this.data.id;
    }

}

export default Invoice;