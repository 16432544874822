import React from "react";
import {format} from "date-fns";
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import {formatDisplayPrice} from "../../../../res/dataServices/pricing";
import {useNavigate} from "react-router-dom";

const HeaderInvoice = ({ invoice }) => {

    const navigate = useNavigate();
    const gotToDetails = () => {
        navigate("/account/ordering/invoices/" + invoice.getID());
    }
    const goToPayment = () => {
        navigate("/account/payment")
    }

    const jsInvoiced = idempiereDate.getDate(invoice.getIssued());
    const issued = format(jsInvoiced, "dd/MM/yy");

    const displayPrice=formatDisplayPrice(invoice.getGrandTotal());

    return (
        <div className="tile placed-order-tile">
            <div className="tile-header account-tile-header-container">
                <p className="account-tile-b" style={{color: "var(--primary)"}}>{invoice.getDocumentNo()}</p>
                <p className="account-tile-b">Issued: {issued}</p>
            </div>
            <div className="account-tile-body">
                <div className="account-tile-content">
                    <div style={{display: "flex", gap: "24px"}}>
                        <div>
                            <p className="account-tile-content-h">Status</p>
                            <p className="account-tile-content-b">{invoice.getDisplayStatus()}</p>
                        </div>
                        <div>
                            <p className="account-tile-content-h">Total</p>
                            <p className="account-tile-content-b">{displayPrice}</p>
                        </div>
                    </div>
                </div>
                <div className="account-tile-action">
                    {!invoice.isPaid() &&
                        <p className="clickable-text"
                           onClick={goToPayment}
                           style={{
                               color: "var(--secondary)",
                               marginTop: "auto",
                               marginBottom: "0px"
                           }}>
                            Make Payment
                        </p>
                    }
                    <p className="clickable-text"
                       style={{
                           color: "var(--secondary)",
                           marginTop: "auto",
                           marginBottom: "0px"
                       }}
                        onClick={gotToDetails}
                    >
                        See Full Details
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HeaderInvoice;