import SearchBar from "./SearchBar";
import SearchItem from "./SearchItem";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import useSearch from "../../../newStructure/services/state/search/useSearch";
import useWebProduct from "../../state/useWebProduct";
import {ProductPurchaseInfo} from "../../../res/product/productPurchaseInfo";
import ProductTile from "../../../newStructure/component/product/ProductTile";
import ProductReactiveContainer from "../../../newStructure/component/product/ProductReactiveContainer";
import '../../../newStructure/component/style/search/searchAndSuggest.css'
import useStoredBreadcrumb from "../../../newStructure/services/state/navigation/useStoredBreadcrumb";

const HoverProduct = ({ product }) => {

    const { webProduct } = useWebProduct({ product });
    const displayProduct = ProductPurchaseInfo.getGenericDisplayProduct(webProduct);

    return (
        <ProductReactiveContainer compact={true}>
            <ProductTile product={displayProduct} />
        </ProductReactiveContainer>
    )

}

const HoverContent = ({ item }) => {
    if(item.isProduct) {
        return <HoverProduct product={item}/>
    }
}

const SearchAndSuggest = () => {

    const navigate = useNavigate();

    const [ searchTerm, setSearchTerm ] = useState();
    const [ highlighted, setHighlighted ] = useState(-1);

    const {
        search,
        results,
    } = useSearch();

    const {
        storeBreadcrumb
    } = useStoredBreadcrumb();

    const onChange = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        search(searchTerm)
    }

    const onEnter = () => {
        if(highlighted < 0) {
            if(searchTerm && searchTerm.length > 1) {
                navigate("/search/" + searchTerm);
            }
        } else {
            handleItemClick(searchItems[highlighted], true)
        }
    }

    const searchItems = results ? results : [];

    const handleItemClick = (item) => {
        storeBreadcrumb(null);
        if(item.isProduct) {
            navigate("/product/" + item.id)
        } else {
            const categoryID = item["M_Product_Category_ID"][0]
            navigate("/category/" + categoryID);
        }
    }

    const onArrow = (isUp) => {
        if(isUp) {
            const i = Math.max(-1, highlighted - 1);
            setHighlighted(i);
        } else {
            const i = Math.min(highlighted + 1, searchItems.length - 1);
            setHighlighted(i)
        }
    }

    const onBlur = () => {
        setHighlighted(-1);
    }

    const onSearchItemHover = (index) => {
        setHighlighted(index)
    }

    return (
        <div className="search-and-suggest-container">
            <SearchBar
                onChange={onChange}
                onEnter={onEnter}
                onArrow={onArrow}
                onBlur={onBlur}
            />
            <div className="search-suggestions-container">
                {searchItems.map((e,i) =>
                    <SearchItem
                        key={i}
                        item={e}
                        showAsHover={i === highlighted}
                        handleClick={() => handleItemClick(e, false)}
                        onMouseEnter={() => onSearchItemHover(i)}
                        showHover={false}
                        // showHover={e.isProduct}
                    >
                        <HoverContent
                            item={e}
                        />
                    </SearchItem>
                )}
            </div>
        </div>
    )
}

export default SearchAndSuggest;