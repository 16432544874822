import {format} from "date-fns";
import './invoice.css'
import {useNavigate} from "react-router-dom";

const Allocation = ({ allocation }) => {

    const navigate = useNavigate();

    const getDisplayAmount = () => {
        const amnt = allocation.Amount;
        return amnt >= 0 ? `£${amnt.toFixed(2)}` : `£${(-amnt).toFixed(2)}`;
    }
    const isCR = allocation.Amount >= 0;
    const invoiceText = allocation.C_Invoice_ID.C_DocType_ID.identifier;

    const onNavigate = () => {
        navigate("/account/vendorInvoice/" + allocation.C_Invoice_ID.id);
    }

    return (
        <div className="inv-allocations">
            <p className="clickable-text" onClick={onNavigate}>{invoiceText}: <span>{allocation.C_Invoice_ID?.DocumentNo}</span></p>
            <p className="inv-alloc-allocated">Allocated: </p>
            <p className="inv-alloc-display-amnt">{getDisplayAmount()}</p>
            {isCR && <p className="inv-alloc-display-cr"><span>CR</span></p>}
        </div>
    )

}

const Payment = ({ payment }) => {

    const dateAccount = new Date(payment.DateAcct);
    const displayDate = format(dateAccount, "dd/MM/yy");

    return (
        <div className="inv-payment-container">
            <p className="inv-h1">{payment.C_DocType_ID?.identifier}</p>
            <div className="inv-payment-info">
                <div>
                    <p className="inv-h">Date Account</p>
                    <p className="inv-v">{displayDate}</p>
                </div>
                <div>
                    <p className="inv-h">Payment Amount</p>
                    <p className="inv-v r">£{payment.PayAmt}</p>
                </div>
            </div>
            <div style={{marginBottom: "18px"}}>
                <p style={{marginTop: "10px"}} className="inv-h">Allocations</p>
                {payment.C_AllocationLine.map((line,i) =>
                    <Allocation key={i} allocation={line} />
                )}
            </div>
        </div>
    )
}

export default Payment;