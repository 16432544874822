
const AccountNavContainer = ({children, className: providedClass}) => {

    const className = "account-nav-container" + (providedClass ? " " + providedClass : "");
    return (
        <div className={className}>
            {children}
        </div>
    )
}

export default AccountNavContainer;