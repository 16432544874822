import {condition, request} from "../../../../../res/rest/restRequest";
import {env} from "../../../../../res/config/env";
import axios from "axios";
import {api} from "../../../../../res/rest/api";

const orderRequest = () => {

    const builder = {};
    const lineReq = request.subQueryList(api.MODEL.C_ORDER_LINE);
    const req = request.model(api.MODEL.C_ORDER)
        .filter(condition.eq("isSoTrx", "true"))
        .filter(condition.in("docStatus", "'CO'", "'CL'"))
        .orderBy("Created DESC")
        .hostURL(env.API_URL);

    builder.top = (top) => {
        req.top(top);
        return builder;
    }

    builder.skip = (skip) => {
        req.skip(skip);
        return builder;
    }

    builder.forIDs = (...ids) => {
        req.filter(condition.in("C_Order_ID", ...ids));
    }

    builder.forDateRange = (startDate, endDate) => {
        if(startDate) req.filter(condition.greaterThan("DateOrdered", startDate));
        if(endDate) req.filter(condition.lessThan("DateOrdered", endDate));
    }

    builder.includeLines = () => {
        req.expand(lineReq);
        return builder;
    }

    builder.includeProducts = () => {
        lineReq.expand(request.subQuery("M_Product_ID"), request.subQuery("C_UOM_ID"), request.subQuery("C_Charge_ID"));
        return builder;
    }

    // builder.includeLocation = () => {
    //     req.expand(request.subQuery("C_BPartner_Location_ID").select("Name"));
    // }

    builder.sendIt = async (session) => {
        req.filter(condition.eq("c_bpartner_id", session.bPartnerID));
        const resp = await axios.get(req.buildRequest(), api.auth(session));
        return {
            records: resp.data.records,
            page: resp.data["page-count"],
            count: resp.data["row-count"]
        };
    }

    return builder;
}

export default orderRequest;