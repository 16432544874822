import {useNavigate} from "react-router-dom";

const HelpDeskCategory = ({ category }) => {

    const items = category["web_help_desk_item"] ? category["web_help_desk_item"] : [];
    const showExpand = items.length > 5;
    const toShow = showExpand ? items.slice(0,4) : items;

    const navigate = useNavigate();

    const handleClick = (e) => {
        if(e.Link) {
            navigate(e.Link);
        } else {
            navigate(`/account/help/desk/${e.id}`);
        }
    }
    const handleSeeMore = () => {
        navigate(`/account/helpDesk/category-${category.id}`)
    }

    return (
        <div className="tile help-desk-tile">
            <div className="tile-header account-tile-header-container">
                <p className="account-tile-header-p">{category.Name}</p>
            </div>
            <div className="help-desk-tile-body">
                {toShow.map((e,i) =>
                    <p
                        key={i}
                        className="help-desk-link"
                        onClick={() => handleClick(e)}>
                        {e.Name}
                    </p>
                )}
                {showExpand &&
                    <p className="help-desk-link" onClick={handleSeeMore}>Show More...</p>
                }
            </div>
        </div>
    )
}

export default HelpDeskCategory;
