import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import HeaderOrder from "../ordering/HeaderOrder";
import {useState} from "react";
import RangeSelector from "../../../account/component/RangeSelector";
import useOrders from "../../../../newStructure/services/state/account/useOrders";
import Pagination from "../../../../newStructure/component/navigation/Pagination";
import useValueChangeListener from "../../../../newStructure/services/state/general/useValueChangeListener";
import SkeletonDiv from "../../../general/loading/SkeletonDiv";
import HeaderSkeleton from "../ordering/HeaderSkeleton";

const DEFAULT_MONTHS = 12;
const DEFAULT_PAGE_SIZE = 10;

const OrderList = ({name, deliveries }) => {

    if(!deliveries || deliveries.length === 0) {
        return null;
    }

    return (
        <div className="account-view-list">
            <p className="page-h2">{name}</p>
            {deliveries.map((o,i) => {
                return (<HeaderOrder key={i} order={o} />)
            })}
        </div>
    )
}

const DEFAULT_PARAMS = {
    includeOrderLines: false,
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE
}

const mapOrders = (orders) => {
    if (!orders) return null;

    const orderByType = {
        pending: [],
        completed: []
    }

    for (const order of orders) {
        if(order.DocStatus.id === 'CL') {
            orderByType.completed.push(order);
        } else {
            orderByType.pending.push(order);
        }
    }

    return orderByType;
}

const AccountLayoutOrders = () => {

    const {
        loading,
        orders,
        count,
        getOrders
    } = useOrders();

    const [ searchParams, setSearchParams ] = useState(DEFAULT_PARAMS);

    const fetchOrders = () => {
        getOrders(searchParams);
    }

    const onPageChange = (pageNo) => {
        setSearchParams({
            ...searchParams,
            pageNo: pageNo,
        })
    }

    useValueChangeListener(fetchOrders, [searchParams], []);

    const onRangeRequest = (startDate, endDate) => {
        if(!loading) {
            const idStartDate = `'${idempiereDate.getDateString(startDate)}'`;
            const idEndDate   = `'${idempiereDate.getDateString(endDate)}'`;

            setSearchParams({
                startDate: idStartDate,
                endDate: idEndDate,
                pageNo: 0,
            })
        }
    }

    const orderInfo = mapOrders(orders);
    console.log(orderInfo);

    return (
        <>
            <p style={{marginTop: "30px", marginBottom: "20px"}} className="page-h1">Orders</p>
            <RangeSelector
                maxDates={DEFAULT_MONTHS}
                buttonText="Load Orders"
                loading={loading}
                onRequest={onRangeRequest}
            />
            {/*<ActiveShipments shipments={deliveryList} />*/}
            {loading &&
                <div className="account-view-list">
                    <SkeletonDiv style={{height: "2.2em", width:"300pt"}}/>
                    {[...Array(4).keys()].map((e,i) =>
                        <HeaderSkeleton v key={i} />
                    )}
                </div>
            }
            {(!loading && (!orders || orders.length === 0)) &&
                <div className="account-view-list">
                    <p className="account-heading-large">No orders</p>
                    <p>We could not find any orders for that time period</p>
                </div>
            }
            {orderInfo &&
                <>
                    <OrderList
                        name="Pending"
                        deliveries={orderInfo.pending} />
                    <OrderList
                        name="In Progress"
                        deliveries={orderInfo.outForDelivery} />
                    <OrderList
                        name="Delivered"
                        deliveries={orderInfo.completed} />
                </>
            }

            <Pagination
                currentPage={(searchParams?.pageNo)}
                loading={loading}
                lastPage={Math.ceil(count / DEFAULT_PAGE_SIZE)}
                onPageRequested={onPageChange}
            />
        </>
    )

}

export default AccountLayoutOrders;
