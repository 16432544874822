import React, {useState} from "react";

const ImageLazyLoad = ({src, alt, className}) => {

    const defaultLoading = src && src.length > 0;
    const [imgLoading, setImgLoading] = useState(defaultLoading);

    const stopImgLoading = () => {
        setImgLoading(false);
    }

    const finalClassName = (className ? className : "") +
        (imgLoading ? " loading" : "")

    return (
        <div className={finalClassName}>
            <img
                src={src}
                alt={alt}
                onLoad={stopImgLoading}
                onError={stopImgLoading}
            />
        </div>
    )

}

export default ImageLazyLoad;