import React from "react";
import ImageLazyLoad from "../../../general/loading/ImageLazyLoad";


const DetailItem = ({heading, headingStyle, body}) => {

    return (
        <>
            <p className="placed-order-detail-h" style={{...headingStyle}}>{heading}</p>
            <p className="placed-order-detail-b">{body}</p>
        </>
    )
}

const LineInvoice = ({ invoiceLine }) => {

    const product = invoiceLine["M_Product_ID"];
    const charge = invoiceLine["C_Charge_ID"];
    const uom = invoiceLine["C_UOM_ID"];

    const getHeader = () => {
        if(product) {
            return product.Value;
        } else if(charge) {
            return "Charge";
        } else {
            return "Other"
        }
    }

    const getDescription = () => {
        if(product) {
            return product.Name;
        } else if(charge) {
            return charge.Name;
        } else {
            return invoiceLine.Description;
        }
    }

    const getQuantity = () => {
        const entered = invoiceLine["QtyEntered"];
        if(uom && uom.Name) {
            return entered + " " + uom.Name;
        }
        return entered;
    }

    const header = getHeader();
    const description = getDescription();
    const imgUrl = product ? product.ImageURL : null;
    const quantity = getQuantity();
    const total = invoiceLine.LineNetAmt;


    return (
        <div className="tile-2-body placed-order-line-body">
            <ImageLazyLoad
                className={"placed-order-line-img"}
                src={imgUrl}
            />
            <DetailItem heading={header}
                        headingStyle={{fontSize: "1em", fontWeight: "500", color:"var(--primary)"}}
                        body={description}/>
            {(product || charge) &&
                <DetailItem
                    heading="Quantity"
                    body={quantity}
                />
            }
            <DetailItem heading={"Total"}
                        body={total}/>
        </div>
    );

}

export default LineInvoice;
