import solrProductRequest from "../../services/rest/request/product/solrProductRequest";
import OrderBy from "../filter/orderBy";

const productSearchParamsBuilder = () => {

    const builder = {};
    const params = {};
    params.filters = {};

    builder.categoryID = (categoryID) => {
        params.categoryID = categoryID;
        return builder;
    }

    builder.pageSize = (pageSize) => {
        params.pageSize = pageSize;
        return builder;
    }

    builder.pageNo = (pageNo) => {
        params.pageNo = pageNo;
        return builder;
    }

    builder.filterAttribute = (attributeID, value) => {
        if(!params.filters[attributeID]) params.filters[attributeID] = [];
        params.filters[attributeID].push(value);
        return builder;
    }

    builder.productIDs = (productIDs) => {
        params.productIDs = productIDs;
        return builder;
    }

    builder.excludedPriceList = (priceListID) => {
        params.excludedPriceListID = priceListID;
        return builder;
    }

    builder.vendorID = (vendorID) => {
        params.vendorID = vendorID;
        return builder;
    }

    builder.sortByID = (sortByID) => {
        params.sortByID = sortByID;
        return builder;
    }

    params.getCategoryID = () => params.categoryID;
    params.getPageSize = () => params.pageSize;
    params.getPageNo = () => params.pageNo;
    params.getStartPosition = () => {
        if(params.pageNo > 1) {
            return ((params.pageNo - 1) * params.pageSize);
        } else {
            return 0;
        }
    }
    params.getVendorID = () => params.vendorID;
    params.getFilterAttributes = () => params.filters;
    params.getProductIDs = () => params.productIDs;
    params.getExclusionPriceList = () => params.excludedPriceListID;
    params.getSortByID = () => params.sortByID;

    builder.getParams = () => params;

    builder.getProductRequest = (priceListID) => getProductRequest(builder.getParams(), priceListID);

    return builder;
}


export const getProductRequest = (searchParams, priceListID) => {

    const req = solrProductRequest();
    req.queryMatching("Table_Name", "M_Product")
    req.priceListExclusion(priceListID);

    if(searchParams.getCategoryID() > 0)
        req.queryMatching("categoryChain", searchParams.getCategoryID());

    if(searchParams.getPageSize() > 0) {
        req.pageSize(searchParams.getPageSize());
        if(searchParams.getStartPosition() > 0)
            req.start(searchParams.getStartPosition());
    }

    if(searchParams.vendorID > 0) {
        req.addFilterQueryItem("vendorID:" + searchParams.vendorID);
    }

    if(searchParams.getProductIDs()?.length > 0) {
        const list = searchParams.getProductIDs().map((id,i) => ({field: "M_Product_ID", value: String(id), score: i}));
        req.queryMatchingOrList(list);
    }

    if(searchParams.sortByID) {
        req.sortBy("score asc");
    }

    const filters = searchParams.getFilterAttributes();
    if(filters) {
        for(const filterId of Object.keys(filters)) {
            if(filterId === '111') {
                const sortType = filters[filterId][0];
                if(sortType === OrderBy.OPTION_A_Z.name) {
                    req.sortBy("descriptionScore asc")
                } else if(sortType === OrderBy.OPTION_Z_A.name) {
                    req.sortBy("descriptionScore desc");
                }

            } else {
                const toFilter = filters[filterId].map(i => ({field: "filters", value: filterId + "=" + i}));
                req.queryMatchingOrList(toFilter);
            }
        }
    }

    return req;
}

export default productSearchParamsBuilder;