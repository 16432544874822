import useSolrProduct from "./useSolrProduct";
import {useState} from "react";
import productSearchParamsBuilder from "../../../logic/product/productSearchParams";
import FilterUrl from "../../../logic/filter/filterUrl";
import MapUtil from "../../../logic/collection/mapUtil";

const DEFAULT_PAGE_SIZE = 20;

const useCategoryPage = (pageNo, categoryID, filterParam) => {

    const {
        error,
        loading,
        products,
        productCount,
        getProducts,
    } = useSolrProduct();

    const [ fetchedPage, setFetchedPage ] = useState({});

    const getParams = () => {
        const filters = FilterUrl.getMap(filterParam);
        const builder = productSearchParamsBuilder()
            .pageSize(DEFAULT_PAGE_SIZE)
            .pageNo(pageNo)
            .categoryID(categoryID);

        if(filters) {
            MapUtil.forEach(filters, (k, v) => {
                v.forEach(fv => builder.filterAttribute(k, fv))
            })
        }

        return builder.getParams();
    }

    if(fetchedPage.pageNo !== pageNo ||
        fetchedPage.categoryID !== categoryID ||
        fetchedPage.filterParam !== filterParam) {
        setFetchedPage({
            pageNo,
            categoryID,
            filterParam
        })
        getProducts(getParams())
    }

    const pageCount = (Math.ceil(productCount / DEFAULT_PAGE_SIZE)) + 1

    return {
        error,
        loading,
        products,
        pageCount
    }

}

export default useCategoryPage;