import useAuthRequest from "../request/useAuthRequest";
import shipmentRequest from "../../rest/request/account/shipmentRequest";

const useShipments = () => {

    const {
        error,
        result,
        loading,
        sendIt
    } = useAuthRequest();

    const getShipments = ({ ids, outForDelivery, includeLines, startDate, endDate, pageSize = 10, pageNo =0 }) => {
        const req = shipmentRequest()
            .top(pageSize)
            .skip((pageNo - 1) * pageSize)

        if(startDate || endDate) {
            req.forDateRange(startDate, endDate);
        }

        if(includeLines) {
            req.includeLines().includeProducts();
        }

        if(outForDelivery) {
            req.outForDelivery();
        }

        if(ids && ids.length > 0) {
            req.forIDs(ids);
        }

        sendIt(req.sendIt)
    }

    return {
        error,
        loading,
        shipments: result?.records,
        pages: result?.page,
        count: result?.count,
        getShipments
    }
}

export default useShipments;