import useInvoices from "../../../../newStructure/services/state/account/useInvoices";
import Invoice from "../../../../newStructure/logic/account/invoice";
import useValueChangeListener from "../../../../newStructure/services/state/general/useValueChangeListener";
import SkeletonDiv from "../../../general/loading/SkeletonDiv";
import {useDispatch} from "react-redux";
import usePrint from "../../../general/output/usePrint";
import {request} from "../../../../res/rest/restRequest";
import {env} from "../../../../res/config/env";
import {api} from "../../../../res/rest/api";
import {reportError} from "../../../../redux/slice/errorReportSlice";
import {useNavigate} from "react-router-dom";
import LineInvoice from "../ordering/LineInvoice";


const AccountLayoutDetailInvoice = ({id}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        loading,
        invoices,
        getInvoices
    } = useInvoices();

    const getTheInvoice = () => {
        getInvoices({
            ids: [id],
            includeLines: true,
        });
    }
    useValueChangeListener(getTheInvoice, [id], []);

    const invoice = invoices && invoices.length > 0 ? new Invoice(invoices[0]) : null;
    const lines = invoice?.data.c_invoiceLine;

    const { loading: loadingPDF, fetchAndPrintIt } = usePrint({
        url: request.print(api.MODEL.C_INVOICE, id).buildRequest(env.API_URL),
        onError: (e) => dispatch(reportError({
            code: "shipmentPrintError",
            error: e && e.message ? e.message : "failed to fetch report"
        }))
    })

    const goToInvoices = () => {
        navigate("/account/ordering/invoices");
    }


    return (
        <div className="account-order-detail-view">
            <div className="account-order-detail-header">
                <p className="clickable-text" onClick={goToInvoices}>Back To Orders</p>
                {loading ?
                    <SkeletonDiv style={{height:"2.4em", width:"min(300pt, 40vw)", marginBottom: "10pt"}}/> :
                    <p className="page-h1">Invoice: {invoice ? invoice.getDocumentNo() : ""}</p>
                }
                <div className="account-order-detail-buttons">
                    {/*<Button>Add all to cart</Button>*/}
                    {/*<Button>Create recurring order</Button>*/}
                    {loadingPDF ?
                        <SkeletonDiv style={{height: "calc(1em + 10px)", width: "10em"}} /> :
                        <p className="clickable-text"
                           onClick={fetchAndPrintIt}
                           style={{margin: "4px 0", color: "var(--secondary)"}}>
                            Print Invoice
                        </p>
                    }
                </div>

                {/* TODO */}
                {/*<div className="account-order-detail-links">*/}
                {/*    <p style={{marginTop: "32px", marginBottom: "12px"}} className="page-h3">Linked Shipments</p>*/}
                {/*    <p style={{marginTop: "24px", marginBottom: "12px"}} className="page-h3">Linked Invoices</p>*/}

                {/*</div>*/}

                <div className="account-order-detail-lines">
                    <p style={{marginTop: "32px", marginBottom: "12px"}} className="page-h3">Invoice Lines</p>
                    <div className="account-order-lines-display">
                        {loading ?
                            [...Array(4).keys()].map((e,i) =>
                                <SkeletonDiv style={{height: "2em", width: "400px"}} key={i}/>) :
                            (!loading && lines) &&
                            lines.map((e,i) =>
                                <LineInvoice
                                    key={i}
                                    invoiceLine={e}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


//
// const AccountLayoutDetailInvoice = ({id}) => {
//
//     const navigate = useNavigate();
//
//     const [ reloaded, setReloaded ] = useState(false);
//     const {
//         ordering,
//         loading,
//         getAnInvoice
//     } = useInvoices();
//
//     if(!reloaded) {
//         setReloaded(true);
//         getAnInvoice(id);
//     }
//
//
//     const invoice = ordering.invoices ? ordering.invoices[id] : null;
//     const linkedShipments = (ordering.shipments && reloaded) ? Object.values(ordering.shipments) : [];
//     const linkedOrders  = (ordering.orders && reloaded)  ? Object.values(ordering.orders)  : [];
//     const displayLoading = !reloaded || loading.invoices || !invoice;
//
//     const dispatch = useDispatch();
//     const { loading: loadingPDF, fetchAndPrintIt } = usePrint({
//         url: request.print(api.MODEL.C_INVOICE, id).buildRequest(env.API_URL),
//         onError: (e) => dispatch(reportError({
//             code: "shipmentPrintError",
//             error: e && e.message ? e.message : "failed to fetch report"
//         }))
//     })
//
//     const getShipment = (invoiceLine) => {
//         const shipmentID = invoiceLine["M_InOut_ID"];
//         if(shipmentID) {
//             return ordering.shipments[shipmentID.id];
//         }
//     }
//     const getOrder = (invoiceLine) => {
//         const orderID = invoiceLine["C_Order_ID"];
//         if(orderID) {
//             return ordering.orders[orderID.id];
//         }
//     }
//
//     return (
//         <div className="account-order-detail-view">
//
//             <div className="account-order-detail-header">
//                 <p className="clickable-text" onClick={() => navigate("/account/ordering/invoices")}>Back To Invoices</p>
//                 {displayLoading ?
//                     <SkeletonDiv style={{height:"2.4em", width:"min(300pt, 40vw)", marginBottom: "10pt"}}/> :
//                     <p className="account-header-2">Invoice: {invoice ? invoice.DocumentNo : ""}</p>
//                 }
//                 <div className="account-order-detail-buttons">
//                     {invoice && invoice.IsPaid &&
//                         <Button>Make Payment</Button>
//                     }
//                     <Button className={loadingPDF ? "button-loading" : null} onClick={fetchAndPrintIt}>Print Invoice</Button>
//                 </div>
//             </div>
//
//             <div className="account-order-detail-links">
//                 <Linked
//                     links={linkedOrders}
//                     header={"Linked Orders"}
//                     loading={loading.orders}
//                     toComponent={(e,i) => <CompactOrder key={i} order={e} />}
//                 />
//                 <Linked
//                     links={linkedShipments}
//                     header={"Linked Shipments"}
//                     loading={loading.shipments}
//                     toComponent={(e,i) => <CompactShipment key={i} shipment={e} />}
//                 />
//             </div>
//
//             <div className="account-order-detail-lines">
//                 <p className="account-header-2">Invoice Lines</p>
//                 <div className="account-order-lines-display">
//                     {displayLoading ?
//                         [...Array(4).keys()].map((e,i) =>
//                             <HeaderSkeleton key={i}/>) :
//                         invoice["web_ordering_invoiceLine_v"].map((e,i) =>
//                             <LineInvoice
//                                 key={i}
//                                 invoiceLine={e}
//                                 order={getOrder(e)}
//                                 shipment={getShipment(e)}
//                             />
//                         )
//                     }
//                 </div>
//             </div>
//
//         </div>
//     )
//
// }

export default AccountLayoutDetailInvoice;
