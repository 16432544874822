import './invoice.css'
import {useNavigate} from "react-router-dom";

const KeyAndVal = ({h, val, r}) => {

    return (
        <div>
            <p className="inv-h">{h}</p>
            <p className={"inv-v" + (r ? " r" : "")}>{val}</p>
        </div>
    )
}

const UnpaidInvoice = ({ invoice }) => {

    const navigate = useNavigate();

    const openBalance = invoice.web_invoice_open_balance_v[0]?.openbalance;

    const onSeeMore = () => {
        navigate("/account/vendorInvoice/" + invoice.id)
    }

    const formatCurrency = (p) => {
        return (p < 0 ? "-" : "") + "£" + (Math.abs(p).toFixed(2));
    }

    return (
        <div className="unpaid-invoice-container">
            <p className="inv-h1">{invoice.DocBaseType?.identifier}</p>
            <div className="unpaid-invoice-vals">
                <KeyAndVal h="Invoice Number" val={invoice.DocumentNo} />
                <KeyAndVal h="Due Date" val={invoice.DueDate} />
                <KeyAndVal h="Invoice Total" val={formatCurrency(invoice.GrandTotal)} r />
                <KeyAndVal h="Open Balance" val={formatCurrency(openBalance)} r />
            </div>
            <p className="clickable-text" onClick={onSeeMore}>See payments / more details</p>
        </div>
    )
}

export default UnpaidInvoice;