import PageLayout from "./PageLayout";
import {Button} from "../general/input/Button";
import {request} from "../../res/rest/restRequest";
import {env} from "../../res/config/env";
import axios from "axios";
import {api} from "../../res/rest/api";
import useSession from "../../newStructure/services/state/session/useSession";

const PageTest = () => {

    const session = useSession();

    const runTest = async () => {
        const req = request.process("movedateordered")
            .param("c_order_ID", 1241150);

        const url = env.API_URL + req.buildRequest();

        axios.post(url, req.buildRequestBody(), api.auth(session?.sessionData))
            .then(res => {
                console.log("resp", res);
            })
            .catch(e => {
                console.log("error", e);
            });
    }

    return (
        <PageLayout>
            <div>
                <Button
                    style={{
                        height: "300px",
                        width: "70vw",
                        margin: "100px auto",
                        textAlign: "center",
                        display: "flex"
                    }}
                    onClick={runTest}>
                    <p style={{
                        margin: "auto auto",
                        fontSize: "10em"
                    }}>Test</p>
                </Button>
            </div>
        </PageLayout>
    )

}

export default PageTest;