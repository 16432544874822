import FormItem from "./FormItem";
import Selector from "../general/input/Selector";

const FormSelector = ({ name, selectionRef, onSelected, options, defaultOption, optionToString, gridRow, gridColumn, description }) => {

    const onSelectionChange = (selection) => {
        if(onSelected) {
            onSelected(selection)
        }
        if(selectionRef) {
            selectionRef.current = selection;
        }
    }

    return (
        <FormItem gridRow={gridRow} gridColumn={gridColumn}>
            <p className="input-form-sub-heading">{name}</p>
            {description && <p className="input-form-text">{description}</p>}
            <Selector
                options={options}
                onSelect={onSelectionChange}
                defaultSelection={defaultOption}
                optionToString={optionToString}
            />
        </FormItem>
    )

}

export default FormSelector;