import '../style/form/form.css'

const FormItem = ({ children, gridColumn, gridRow, style:passedStyle={}, className }) => {

    const style = {...passedStyle}
    if(gridColumn) style.gridColumn = gridColumn;
    if(gridRow) style.gridRow = gridRow;

    return (
        <div style={style} className={"input-form-item" + (className ? " " + className : "")}>
            {children}
        </div>
    )
}

export default FormItem;