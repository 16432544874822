import Form from "../../form/Form";
import FormHeading from "../../form/FormHeading";
import FormInput from "../../form/FormInput";
import {useRef, useState} from "react";
import FormText from "../../form/FormText";
import FormCheckBox from "../../form/FormCheckBox";
import FormButton from "../../form/FormButton";
import Dialog from "../../general/dialog/Dialog";
import NewProductValidation from "../../../logic/vendor/newProductValidation";
import Popup from "../../../../components/general/popup/Popup";
import useAuthRequest from "../../../services/state/request/useAuthRequest";
import newProductRequest from "../../../services/rest/request/vendor/newProductRequest";

const NewProductForm = () => {

    const [ useBrandBank, setUseBrandBank ] = useState(true);
    const [ warning, setWarning ] = useState();
    const { loading, sendIt, result } = useAuthRequest();
    console.log(useBrandBank);

    const ref = useRef({});

    const setRefValue = (key) => (val) => {
        ref.current[key] = val;
    }


    const submit = () => {
        const valid = NewProductValidation.validate(ref.current);
        if(!valid.isValid) {
            setWarning(valid.msg);
        } else {
            const req = newProductRequest()
                .data(ref.current);
            sendIt(req.sendIt);
        }
    }

    if(result && false) {
        return (
            <>
                <Form>
                    <FormText name="Form Submitted">
                        Thank you! Your form has been submitted. A member of our team will review the form and get back to you promptly.
                    </FormText>
                    <div style={{marginBottom: "200px"}} />
                </Form>
            </>
        )
    }

    return (
        <>
            {loading &&
                <Popup>
                    <Dialog
                        title="Submitting Form"
                        message="Submitting form please wait"
                        showLoading
                    />
                </Popup>
            }
            {warning &&
                <Popup>
                    <Dialog
                        title="Missing Data"
                        message={warning}
                        neutralButton={{name: "OK", onClick: () => setWarning(null)}}
                    />
                </Popup>
            }
            <Form>
                <FormHeading gridColumn="span 2">Product Details</FormHeading>
                <FormInput name="Name*" onValueChange={setRefValue("name")}/>
                <FormInput name="Supplier Code*"  onValueChange={setRefValue("VendorProductNo")}
                    gridColumn="1"/>
                <FormInput name="Case Barcode*"  onValueChange={setRefValue("casebarcodecurrent")}
                    gridColumn="2" gridRow="2"/>
                <FormInput name="Inner Barcode*" onValueChange={setRefValue("itembarcodecurrent")} />
                <FormInput name="RRP" onValueChange={setRefValue("PriceStd")} gridColumn="1" gridRow="4"/>
                {/*<FormCheckBox text="Price Marked" onValueChange={setRefValue("isPriceMark")} gridColumn="1" gridRow="5"/>*/}
                <FormInput name="Product Unit (e.g. 75cl, 250g)" onValueChange={setRefValue("X_ProductUnit")} />
            </Form>
            <Form>
                <FormHeading gridColumn="span 2">Purchase Info</FormHeading>
                <FormInput name="Pack Purchase Price*" onValueChange={setRefValue("PricePO")} />
                <FormInput name="Full Pallet Quantity" onValueChange={setRefValue("UnitsPerPallet")}
                           gridColumn="2"/>
                <FormInput name="Order Pack Size" onValueChange={setRefValue("Order_Pack")} />
                <FormInput name="Pallet Layer Quantity" onValueChange={setRefValue("layerqty")}
                           gridColumn="2"/>
                <FormInput name="Minimum Order Quantity" onValueChange={setRefValue("Order_Min")} />
            </Form>
            <Form>
                <FormHeading gridColumn="span 2">Other</FormHeading>
                <FormInput name="Comments/ Notes" onValueChange={setRefValue("DocumentNote")}></FormInput>
            </Form>
            <Form>
                <FormHeading gridColumn="span 2">Branding Info</FormHeading>
                <FormText>If your product is listed with NIQ brandbank, we will be able to import branding data from there. </FormText>
                <FormCheckBox gridColumn={1} text={"Import From BrandBank"} defaultChecked={true} onCheckedChanged={setUseBrandBank} />
            </Form>
            <Form>
                <div style={{height: "100px", gridColumn: 2}}></div>
                <FormButton gridColumn={2} onClick={submit}>Submit For Review</FormButton>
            </Form>
            <div style={{height: "100px"}} />
        </>
    )

}

export default NewProductForm;