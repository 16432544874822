import DatePicker from "react-datepicker";
import React from "react";

// const getXMonthsAway = (date, months) => {
//     const monthsAway = new Date(date);
//     monthsAway.setMonth(date.getMonth() + months);
//     return monthsAway;
// }

const getSelectableMonths = (date, monthsAway) => {
    const first = new Date(date);
    const selectableDates = [first];
    for(let i = 1; i < monthsAway; i++) {
        const nextDate = new Date(date);
        nextDate.setMonth(date.getMonth() + (i));
        selectableDates.push(nextDate);
    }
    return selectableDates;
}

const RangeMonthSelector = ({ maxMonths, startDate, endDate, onChange, children }) => {

    const handleStartDateChange = (date) => {
        if(onChange) {
            onChange({
                startDate: date,
                endDate: null,
            });
        }
    };

    const handleEndDateChange = (date) => {
        if(onChange) {
            onChange({
                startDate: startDate,
                endDate: date,
            });
        }
    };


    const selectableMonths = startDate ? getSelectableMonths(startDate, maxMonths) : null;

    return (
        <div className="date-picker-wrapper month-picker">
            <DatePicker
                showMonthYearPicker
                dateFormat="MM/yyyy"
                selected={startDate}
                onChange={handleStartDateChange}
                placeholderText={"From"}
            />
            <DatePicker
                showMonthYearPicker
                dateFormat="MM/yyyy"
                selected={endDate}
                onChange={handleEndDateChange}
                includeDates={selectableMonths}
                disabled={!Boolean(startDate)}
                placeholderText={"To"}
            />
            {children}
        </div>
    );
}

export default RangeMonthSelector;