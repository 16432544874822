import {priceService} from "../../../../res/dataServices/pricing";
import {Button} from "../../../general/input/Button";
import {useNavigate} from "react-router-dom";
import useCreditStatus from "../../../../newStructure/services/state/account/useCreditStatus";

const KEY_LIMIT     = "SO_CreditLimit";
const KEY_BALANCE   = "TotalOpenBalance";
const KEY_CALC      = "creditLimitCalc";

const AccountLayoutCreditStatus = () => {

    const navigate = useNavigate();
    const { creditStatus } = useCreditStatus();

    const limit = creditStatus ? priceService.formatDisplayPrice(creditStatus[KEY_LIMIT]) : "---";
    const balance = creditStatus ? priceService.formatDisplayPrice(creditStatus[KEY_BALANCE]) : "---";
    const calc = creditStatus ? creditStatus[KEY_CALC] : "...";

    const onMakePaymentClick = () => {
        navigate("/account/payment")
    }

    return (
        <>
            <h1>Credit Status</h1>
            <div className="account-content-container">
                <div className="credit-status-container">
                    <h4>Current Credit Limit</h4>
                    <p>{limit}</p>
                    <h4>Current Open Amount</h4>
                    <p>{balance}</p>
                    <h4>Credit Limit Calculation</h4>
                    <p>{calc}</p>
                    {/*<Button>Download Invoices</Button>*/}
                    <Button onClick={onMakePaymentClick}>Make Payment</Button>
                </div>
            </div>
        </>
    )
}

export default AccountLayoutCreditStatus;
