import React from "react";
import { format } from "date-fns";
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import {formatDisplayPrice} from "../../../../res/dataServices/pricing";
import {useNavigate} from "react-router-dom";

const PlacedOrderHeader = ({order}) => {

    const jsDateOrdered = idempiereDate.getDate(order.DateOrdered);
    const formattedOrdered = format(jsDateOrdered, "dd/MM/yy");

    return (
        <div className="tile-header account-tile-header-container">
            <p className="account-tile-b" style={{color: "var(--primary)"}}>{order.DocumentNo}</p>
            <p className="account-tile-b">Ordered: {formattedOrdered}</p>
            <p className="account-tile-b">{order.C_BPartner_Location_ID.identifier}</p>
        </div>
    )
}

const PlacedOrderBody = ({order}) => {

    const navigate = useNavigate();
    const onSeeFullDetails = () => {
        navigate("/account/ordering/orders/" + order.id);
    }
    const displayPrice = formatDisplayPrice(order.GrandTotal);

    return (
        <div className="account-tile-body">
            <div className="account-tile-content">
                <p className="account-tile-content-h">Total</p>
                <p className="account-tile-content-b">{displayPrice}</p>
            </div>
            <div className="account-tile-action">
                <p style={{color: "var(--secondary)"}} className="clickable-text">Report Problem</p>
                <p style={{color: "var(--secondary)"}} className="clickable-text" onClick={onSeeFullDetails}>See Full Details</p>
            </div>
        </div>
    )
}

const HeaderOrder = ({order}) => {

    return (
        <div className="tile placed-order-tile">
            <PlacedOrderHeader order={order} />
            <PlacedOrderBody order={order}/>
        </div>
    )
}

export default HeaderOrder;