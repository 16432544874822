
const highlight = "#01A2E0"
const hidden = "#E6E6E6"

const BannerSVGMobile = ({ leaderboard, isLeaderBoard }) => {

    const leaderboardColour = isLeaderBoard ? highlight : hidden;

    return (
        <svg width="220" height="476" viewBox="0 0 220 476" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="220" height="476" fill="#F8F8F8"/>
            <rect x="49" y="26" width="76" height="10" rx="5" fill="#E6E6E6"/>
            <rect x="49" y="38" width="76" height="10" rx="5" fill="#E6E6E6"/>
            <rect x="132" y="18" width="38" height="38" rx="19" fill="#E6E6E6"/>
            <rect x="28" y="67" width="163" height="26" rx="10" fill="#D9D9D9"/>
            <rect x="12" y="168" width="26" height="7" rx="3.5" fill="#D9D9D9"/>
            <rect x="56" y="168" width="29" height="7" rx="3.5" fill="#D9D9D9"/>
            <rect x="103" y="168" width="35" height="7" rx="3.5" fill="#D9D9D9"/>
            <rect x="7" y="74" width="14" height="2" rx="1" fill="#D9D9D9"/>
            <rect x="7" y="78" width="14" height="2" rx="1" fill="#D9D9D9"/>
            <rect x="198" y="72" width="14" height="13" rx="6.5" fill="#D9D9D9"/>
            <rect x="7" y="82" width="14" height="2" rx="1" fill="#D9D9D9"/>
            <rect y="109" width="220" height="2" fill="#E6E6E6"/>
            <rect y="111" width="220" height="2" fill="white"/>
            <rect y="113" width="220" height="2" fill="#CBCBCB"/>
            <rect x="12" y="193" width="95" height="277" rx="10" fill="#E6E6E6"/>
            <path d="M12 203C12 197.477 16.4772 193 22 193H97C102.523 193 107 197.477 107 203V214H12V203Z"
                  fill="#D4D4D4"/>
            <rect x="113" y="193" width="95" height="277" rx="10" fill="#E6E6E6"/>
            <path d="M113 203C113 197.477 117.477 193 123 193H198C203.523 193 208 197.477 208 203V214H113V203Z"
                  fill="#D4D4D4"/>
            {leaderboard ?
                <image href={leaderboard} x="16" y="125" width="187" height="29" preserveAspectRatio="xMidYMid slice"/> :
                <rect x="16" y="125" width="187" height="29" fill={leaderboardColour}/>
            }
        </svg>
    )
}

export default BannerSVGMobile;