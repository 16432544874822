import AccountNavHeader from "./AccountNavHeader";
import './../style/account-nav.css'
import AccountNavContainer from "./AccountNavContainer";
import AccountNavItem from "./AccountNavItem";
import {useMediaQuery} from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import useLogOut from "../../state/account/useLogOut";

const navTree = [
    {
        name: "Account and Payments",
        nav: "/account/details",
        children: [
            {
                name: "Business Colleagues",
                nav: "/account/details/colleagues"
            },
            {
                name: "Business Locations",
                nav: "/account/details/locations"
            },
            {
                name: "Credit Status and Payments",
                nav: "/account/details/credit"
            },
            {
                name: "Billing Information",
                nav: "/account/details/billing"
            },
            {
                name: "Statements",
                nav: "/account/details/statements"
            },
        ]
    },
    {
        name: "Orders",
        nav: "/account/ordering",
        children: [
            {
                name: "Orders",
                nav: "/account/ordering/orders"
            },
            {
                name: "Active Back Orders",
                nav: "/account/ordering/activeBackOrders"
            },
            {
                name: "Shipments",
                nav: "/account/ordering/shipments"
            },
            {
                name: "Invoices",
                nav: "/account/ordering/invoices"
            }
        ]
    },
    {
        name: "Help Desk",
        nav: "/account/help",
        children: [
            {
                name: "Get Help",
                nav: "/account/help/desk"
            },
            {
                name: "My Requests",
                nav: "/account/help/requests"
            },
        ]
    }
]

const NavTreeItem = ({item, currentPage}) => {

    const isSelected = item.nav === currentPage;

    return (
        <AccountNavItem name={item.name} nav={item.nav} selected={isSelected}>
            {item.children && item.children.map((e,i) =>
                <NavTreeItem item={e} key={i} currentPage={currentPage} />
            )}
        </AccountNavItem>
    )

}

const LogOutButton = () => {
    const logOut = useLogOut();

    const handleLogOut = () => {
        logOut();
    }

    return (
        <p className="log-out-button" onClick={handleLogOut}>Log Out</p>
    )
}

const DesktopNav = ({currentPage}) => {

    return (
        <>
            <AccountNavHeader />
            {navTree.map((e,i) =>
                <AccountNavContainer>
                    <NavTreeItem key={i} item={e} currentPage={currentPage} />
                </AccountNavContainer>
            )}
            <LogOutButton />
        </>
    )
}

const MobileNavItem = ({navItem, selected, currentPage, onClick}) => {

    const isSelected = navItem.nav === currentPage ||
        selected?.name === navItem.name;

    if(isSelected) console.log(navItem)

    return (
        <div
            onClick={() => onClick(navItem)}
            className={"account-nav-item-mobile" + (isSelected ? " nav-item-selected" : "")}>
            <p>{navItem.name}</p>
        </div>
    )

}

const MobileNav = ({navList, currentPage}) => {

    const navigate = useNavigate();
    const [ selected, setSelected ] = useState();

    const handleSelected = (navItem) => {
        setSelected(navItem);
        navigate(navItem.nav);
    }

    return (
        <>
            <div className="account-nav-menu-mobile">
                {navList.map((e,i) =>
                    <MobileNavItem
                        navItem={e}
                        currentPage={currentPage}
                        selected={selected}
                        onClick={handleSelected}
                    />
                )}
            </div>
            {selected && selected.children &&
                <MobileNav currentPage={currentPage} navList={selected.children} />
            }
        </>
    )

}

const AccountNav = ({currentPage}) => {

    const smallScreen = useMediaQuery('(max-width:768px)');

    return (
        <div className="account-nav">
            {smallScreen ?
                <MobileNav currentPage={currentPage} navList={navTree} /> :
                <DesktopNav currentPage={currentPage} />
            }
        </div>
    )
}

export default AccountNav;
